// import { injectable } from "inversify";
import * as Request from "superagent";

// export interface IFileUploadUtilities {
//     upload(
//         fileObject: any,
//         sas: any,
//         fileName: string,
//         uploadProgressCallback?: any,
//         uploadCompleteCallback?: any
//     ): void;
//     isFileExist(event: any): boolean;
// }

// @injectable()
export const FileUploadUtilities = {
    upload: (
        fileObject: any,
        sas: any,
        fileName: string,
        uploadProgressCallback?: any,
        uploadCompleteCallback?: any
    ): void => {
        let fileToUpload = {
            maxBlockSize: 0,
            file: 0,
            size: 0,
            uploadFailed: false,
            bytesUploaded: 0,
            sasUrl: 0,
            completeEvent: 0,
            blockIds: new Array(),
            uploadedIds: new Array(),
            filecontent: new Array(),
            blockUploaded: new Array(),
            blockIdPrefix: "",
            numberOfBlocks: 0,
            fileName: "",
            fileGUID: ""
        };
        fileToUpload.maxBlockSize = 4096 * 1024;
        fileToUpload.file = fileObject;
        fileToUpload.size = fileObject.size;
        fileToUpload.uploadFailed = false;
        fileToUpload.bytesUploaded = 0;
        if (fileToUpload.size < fileToUpload.maxBlockSize) {
            fileToUpload.maxBlockSize = fileToUpload.size;
        }
        if (fileToUpload.size % fileToUpload.maxBlockSize == 0) {
            fileToUpload.numberOfBlocks = fileToUpload.size / fileToUpload.maxBlockSize;
        } else {
            fileToUpload.numberOfBlocks = parseInt((fileToUpload.size / fileToUpload.maxBlockSize).toString(), 10) + 1;
        }
        fileToUpload.sasUrl = sas;
        fileToUpload.blockIdPrefix = "block-";
        fileToUpload.fileName = fileName;
        FileUploadUtilities.uploadFileInBlocks(fileToUpload, uploadProgressCallback, uploadCompleteCallback);
    },
    // check whether onDragStart event contains files

    isFileExist: (event: any): boolean => {
        if (event.dataTransfer.types) {
            for (var i = 0; i < event.dataTransfer.types.length; i++) {
                if (event.dataTransfer.types[i] == "Files") {
                    return true;
                }
            }
        }

        return false;
    },

    uploadFileInBlocks: (fileToUpload: any, uploadProgressCallback?: any, uploadCompleteCallback?: any) => {
        for (let i = 0; i < fileToUpload.numberOfBlocks; i++) {
            let fileContent;
            if (i == fileToUpload.numberOfBlocks - 1)
                fileContent = fileToUpload.file.slice(i * fileToUpload.maxBlockSize);
            else
                fileContent = fileToUpload.file.slice(
                    i * fileToUpload.maxBlockSize,
                    (i + 1) * fileToUpload.maxBlockSize
                );

            var blockId = fileToUpload.blockIdPrefix + FileUploadUtilities.pad(fileToUpload.blockIds.length, 6);
            fileToUpload.blockIds.push(btoa(blockId));
            fileToUpload.filecontent.push(fileContent);
        }
        var ajaxcaller = 10;
        if (fileToUpload.numberOfBlocks < 10) ajaxcaller = fileToUpload.numberOfBlocks;
        for (let i = 0; i < ajaxcaller; i++) {
            fileToUpload.nextItemtoUpload = ajaxcaller;
            FileUploadUtilities.initReaderObject(
                fileToUpload,
                fileToUpload.blockIds[i],
                fileToUpload.filecontent[i],
                i,
                uploadProgressCallback,
                uploadCompleteCallback
            );
        }
    },

    pad: (number: number, length: number) => {
        var str = "" + number;
        while (str.length < length) {
            str = "0" + str;
        }
        return str;
    },

    initReaderObject: (
        fileToUpload: any,
        blockId: any,
        fileContent: any,
        blockIndex: number,
        uploadProgressCallback?: any,
        uploadCommittCallBack?: any
    ) => {
        let readerObject: any = new FileReader();
        let _this = this;
        readerObject.onloadend = function (evt: any) {
            if (evt.target.readyState == 2) {
                // DONE == 2
                var uri = fileToUpload.sasUrl + "&comp=block&blockid=" + blockId;
                var requestData = new Uint8Array(evt.target.result);
                var encryptedFileName = "";
                try {
                    encryptedFileName = btoa(fileToUpload.file.name);
                } catch (exp) {
                    try {
                        encryptedFileName = btoa(encodeURIComponent(fileToUpload.file.name));
                    } catch (exp) {
                        encryptedFileName = fileToUpload.file.name.replace(/\W/g, "");
                        encryptedFileName = btoa(encryptedFileName);
                    }
                }

                Request.put(uri)
                    .set("Access-Control-Allow-Origin", "*")
                    .set("x-ms-blob-type", "BlockBlob")
                    .set("x-ms-blob-content-disposition", "attachment;filename=" + '"' + encryptedFileName + '"')
                    .set("x-ms-meta-filename", encryptedFileName)
                    .set("x-ms-meta-filetype", fileToUpload.file.type)
                    .send(requestData)
                    .on(
                        "progress",
                        function (e: any) {
                            if (e.direction == "upload") {
                                fileToUpload.blockUploaded[blockIndex] = e.loaded;
                                if (typeof uploadProgressCallback == "function") {
                                    let blockUploadedSize = fileToUpload.blockUploaded.reduce(
                                        (a: number, b: number) => a + b,
                                        0
                                    );
                                    let percent = (blockUploadedSize / fileToUpload.size) * 100;
                                    uploadProgressCallback(percent, fileToUpload);
                                } else {
                                    if (uploadProgressCallback) {
                                        console.error("Error in status update", "");
                                    }
                                }
                            }
                        }.bind(this)
                    )
                    .end((err, res) => {
                        fileToUpload.bytesUploaded += requestData.length;
                        fileToUpload.uploadedIds.push(blockId);
                        FileUploadUtilities.statusUpdator(fileToUpload, "partial");
                        if (fileToUpload.blockIds[fileToUpload.nextItemtoUpload]) {
                            FileUploadUtilities.initReaderObject(
                                fileToUpload,
                                fileToUpload.blockIds[fileToUpload.nextItemtoUpload],
                                fileToUpload.filecontent[fileToUpload.nextItemtoUpload],
                                fileToUpload.nextItemtoUpload,
                                uploadProgressCallback,
                                uploadCommittCallBack
                            );
                            fileToUpload.nextItemtoUpload = fileToUpload.nextItemtoUpload + 1;
                        } else {
                            if (fileToUpload.uploadedIds.length === fileToUpload.blockIds.length)
                                FileUploadUtilities.commitBlockList(fileToUpload, uploadCommittCallBack);
                        }
                    });
            }
        };
        readerObject.readAsArrayBuffer(fileContent);
    },

    statusUpdator: (fileToUpload: any, stauts: any) => {
        //TODO
        if (fileToUpload.completeEvent == 1) {
        } else if (fileToUpload.completeEvent == 2) {
        } else {
        }
    },

    commitBlockList: (fileToUpload: any, uploadCommittCallBack?: any) => {
        let _this = this;
        var uri = fileToUpload.sasUrl + "&comp=blocklist";
        var requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
        for (var i = 0; i < fileToUpload.blockIds.length; i++) {
            requestBody += "<Latest>" + fileToUpload.blockIds[i] + "</Latest>";
        }
        requestBody += "</BlockList>";
        var encryptedFileName = "";
        try {
            encryptedFileName = btoa(fileToUpload.fileName);
        } catch (exp) {
            try {
                encryptedFileName = btoa(encodeURIComponent(fileToUpload.fileName));
            } catch (exp) {
                encryptedFileName = fileToUpload.fileName.replace(/\W/g, "");
                encryptedFileName = btoa(encryptedFileName);
            }
        }

        Request.put(uri)
            .set("Access-Control-Allow-Origin", "*")
            .set("x-ms-blob-content-disposition", "attachment;filename=" + '"' + encryptedFileName + '"')
            .set("x-ms-meta-filename", encryptedFileName)
            .set("x-ms-meta-filetype", fileToUpload.file.type)
            .send(requestBody)
            .end((err: any, res: any) => {
                FileUploadUtilities.statusUpdator(fileToUpload, "success");
                if (uploadCommittCallBack != undefined && typeof uploadCommittCallBack == "function")
                    uploadCommittCallBack(fileToUpload);
            });
    }
};
