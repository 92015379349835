import { DataAccessFactory } from "../../../../utilities/dataAccess/factory/DataAccessFactory";

const DataAccess: any = DataAccessFactory.GetDataAccessService();

export const HeaderInfoServices = {
    getHeaderInfo: (clientGuid: string) => {
        return DataAccess.get("api/Coverpage/GetHeaderInfoAsync/" + clientGuid);
    },
    requestHeaderInfo: (clientGuid: string) => {
        return DataAccess.get("api/Helper/GetHeaderInfoAsync/" + clientGuid);
    },
    updateClientLogout: (clientGuid: string) => {
        return DataAccess.post("", "api/OrganizerClient/UpdateClientLogoutTime/" + clientGuid);
    },
    getProfileData: (clientGuid: string) => {
        return DataAccess.get("api/ApplicationInsight/BasicDetails/" + clientGuid);
    },
};
