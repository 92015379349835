import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { InputFile, Shimmer } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import {
    documentAction, HeaderInfoAction,
    actionCreators as uploadedDocumentStatusAction,
    esignAction
} from "../../../redux/actions";
import { useParams } from "react-router";
import { images } from "../../../assets";
import { DocumentsPageFooter } from "./parts/footer";
import { OrganizerConstants, OrganizerUploadedDocuments, SourceDocumentConstants } from "../../../common";
import { ILocalStore } from "../../../core/utilities";
import { container, TYPES } from "../../../core/startup";
import { UploadedDocumentStoreState } from "redux/reducers/uploadedDocumentStatus/model";
import { ModalNotification } from "components/common/notification/ModalNotification";
import { Button } from "react-bootstrap";

const LocalStorageService = container.get<ILocalStore>(TYPES.ILocalStore);

export const UploadDocsPage: React.FC<{}> = () => {
    const { cameraIcon } = images;

    const dispatch = useDispatch();
    const params: any = useParams();

    const [client, setClient] = useState<any>(null);
    const [infoDivHeight, setInfoDivHeight] = useState(0);
    const infoDivRef = useRef<any>(null);
    const [footerDivHeight, setFooterDivHeight] = useState(0);
    const footerDivRef = useRef<any>(null);
    const [staticDivHeight, setStaticDivHeight] = useState(0);
    const staticDivRef = useRef<any>(null);

    const [showCompleteConfirmationPopup, setShowCompleteConfirmationPopup] = useState(false);

    const { bookmarks, uploadedDocuments, deletedDocuments } = useSelector((state: any) => state);

    const {
        isCompleted, isError, errorMessage,
        loading, uploadedDocumentStatus
    } = useSelector((state: { uploadedDocumentStatusReducer: UploadedDocumentStoreState }) => state.uploadedDocumentStatusReducer);

    const handleRedirect = (url: string) => {
        window.location.href = url;
    };

    const onComplete = () => {
        dispatch(uploadedDocumentStatusAction.changeSourceDocumentStatusToCompleted(params.id, client.clientType, 0, () => {
            handleRedirect(OrganizerConstants.WelcomeScreenURL + params.id);
        }))
    }

    const onSaveAction = () => {
        dispatch(
            documentAction.completeDocumentUpload(params.id, deletedDocuments, () => {
                handleRedirect(OrganizerConstants.WelcomeScreenURL + params.id);
            })
        );
    };

    const setCompleted = () => {
        dispatch(uploadedDocumentStatusAction.setUploadedDocumentError());
        dispatch(uploadedDocumentStatusAction.setUploadedDocumentCompleted());
    }

    const onErrorPopupClose = () => {
        dispatch(documentAction.getUploadedDocuments(params.id));
        dispatch(uploadedDocumentStatusAction.resetUploadedDocumentError());
    }

    useEffect(() => {

        dispatch(
            documentAction.getBookmarks(
                params.id,
                LocalStorageService.getItemByUniqueKey(params.id, SourceDocumentConstants.SourceDocumentEnabled)
            )
        );

        dispatch(documentAction.getUploadedDocuments(params.id));

        dispatch(HeaderInfoAction.setHeaderText(OrganizerConstants.UploadDocumentsHeaderText));

        dispatch(HeaderInfoAction.setHeaderActionButton("fa fa-arrow-left", onSaveAction));

        dispatch(uploadedDocumentStatusAction.requestUploadedDocumentStatus(params.id));

        dispatch(
            esignAction.getDocumentStatusAndSourceFileSettings(params.id, (response: any) => {
                setClient(response.data.client);
            })
        );

        window.addEventListener("resize", setResponsiveHeight, false);

        return () => {
            dispatch(HeaderInfoAction.removeHeaderActionButton());
            window.removeEventListener("resize", setResponsiveHeight)
        }

    }, []);

    const setResponsiveHeight = () => {
        infoDivRef.current && setInfoDivHeight(infoDivRef.current.clientHeight);
        footerDivRef.current && setFooterDivHeight(footerDivRef.current.clientHeight);
        staticDivRef.current && setStaticDivHeight(staticDivRef.current.clientHeight);
    }

    useLayoutEffect(() => {
        setResponsiveHeight();
    }, [loading, client, bookmarks, setResponsiveHeight])

    const hideConfirmPopup = () => {
        setShowCompleteConfirmationPopup(false);
    }

    return (
        <>
            <div data-testid="documentsPage" className="upload-documents">
                <div className="upload-documents-container" ref={infoDivRef}>
                    <div className="upload-documents-message-content">
                        {
                            loading || !client ?
                                <Shimmer width={50} /> :
                                !isCompleted ? OrganizerUploadedDocuments.ModalBodyText :
                                    uploadedDocumentStatus && (client.clientType == uploadedDocumentStatus.actedBy) ?
                                        "You have completed source document upload. You will not be able to upload further documents." :
                                        `${client.spouseName} has completed source document upload. You will not be able to upload further documents.`
                        }
                        <div className="upload-documents-table-warning-msg">
                               <b>Important! </b>{OrganizerUploadedDocuments.UploadDocumentWarningText}
                        </div>
                    </div>
                </div>
                <div className="upload-documents-container" style={{ height: `calc(100% - ${footerDivHeight}px - ${infoDivHeight}px + 50px)` }}>
                    <div className="upload-documents-table-header" ref={staticDivRef}>
                        <strong>Upload Documents</strong>
                        {uploadedDocuments.length ? <a data-testid="totalUploadCount" style={{ color: "#000" }} href="#">Total Uploaded: {uploadedDocuments.length}</a> : <></>}
                    </div>
                    <div className="upload-documents-table-content" style={{ height: `calc(100% - ${staticDivHeight}px)` }}>
                        {
                            bookmarks.map((data: any, index: number) => (
                                <div key={index} className="document-container">
                                    <div className="left-column">{data.bookMark}</div>
                                    <div className="right-column">
                                        <InputFile
                                            image={cameraIcon}
                                            documentData={data}
                                            uploadedDocuments={uploadedDocuments}
                                            isCompleted={isCompleted}
                                            setCompleted={setCompleted}
                                        />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="documents-footer-container">
                <DocumentsPageFooter
                    isCompleteDisabled={!uploadedDocuments.length || isCompleted || loading}
                    onComplete={() => {
                        setShowCompleteConfirmationPopup(true);
                    }}
                    setRef={(ins: any) => { footerDivRef.current = ins }}
                />
            </div>

            {
                isError &&
                <ModalNotification
                    show={isError}
                    modalBodyText={errorMessage}
                    okButtonEnabled={true}
                    okButtonText={"Ok"}
                    onOkButtonClick={onErrorPopupClose}
                />
            }

            {
                showCompleteConfirmationPopup &&
                <ModalNotification
                    closeButton={true}
                    onHide={hideConfirmPopup}
                    show={showCompleteConfirmationPopup}
                    modalBodyText={
                        <div className="font-size-14p" >Have you finished uploading all necessary source documents?<br /> Once you confirm, you will not be able to upload additional documents.</div>
                    }
                    okButtonEnabled={false}
                    modalHeaderText={
                        <div className="font-size-16p font-weight-bold" >Are You Sure?</div>
                    }
                    footerChildrens={
                        <>
                            <Button
                                data-test-auto="4418D370-E36E-4AE7-BD89-97EF3758CF27"
                                onClick={hideConfirmPopup}
                                className="btn btn-secondary btn-sm margin-right-10p"
                                id="btnCancel"
                            >
                                Cancel
                            </Button>

                            <Button className={"btn-primary-extended"}
                                data-test-auto="0CDC79C8-111C-49C2-A196-AD8CD3D2D363"
                                onClick={() => {
                                    onComplete();
                                    hideConfirmPopup();
                                }}>
                                Confirm
                            </Button>
                        </>
                    }
                />
            }

        </>
    );
};

