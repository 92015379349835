import { DataAccessFactory } from "../../../../utilities/dataAccess/factory/DataAccessFactory";
import { ISignerDocumentModel } from "../../../../../models/esign/signDocument";
import { SignerDocumentModel} from "../../../../../models/organizer/organizer"
import { UploadMethod } from "../../../../../models/common/enums";
import { IClientInfo } from "../../../../../models";

const DataAccess: any = DataAccessFactory.GetDataAccessService();

export const EsignServices = {

    getEngagementDocument: (clientId: string) => {
        return DataAccess.get("api/EngagementLetter/Mobile/GetEngagementLetterModel/" + clientId);
    },

    getDocumentStatusAndSourceFileSettings: (clientId: string) => {
        const timestamp: string = new Date().getTime().toString();
        return DataAccess.get("api/Document/GetDocumentStatus/" + clientId + "&timestamp=" + timestamp + "");
    },

    getOrganizerMetadata: (clientId: string) => {
        return DataAccess.get("api/Document/GetOrganizerMetadata/" + clientId);
    },

    submitSignedDocument: (clientId: string, signedDocument: ISignerDocumentModel) => {
        let url: string = "";
        let postObject ={}
        if (signedDocument.uploadMethod == UploadMethod.Batch) {
            url = "api/EngagementLetter/Mobile/Batch/Sign/";            
        } else {
            url = "api/EngagementLetter/Mobile/Sign/";            
        }
        postObject = { formGroup :signedDocument.formGroup, esignDocs: signedDocument.additionalEsign };
        return DataAccess.postJson(postObject, url + clientId);
    },

    skipEngagementSign: (clientId: string, signedDocument: SignerDocumentModel) => {
        let url: string = "";
        if (signedDocument.uploadMethod == UploadMethod.Batch) {
            url = "api/EngagementLetter/Batch/Skip/";
        } else {
            url = "api/EngagementLetter/Skip/";
        }
        return DataAccess.postJson(signedDocument.formGroup, url + clientId);
    },

    updateSpouseEmail: (clientId: string, clientInfo: IClientInfo) => {
        let url: string = "api/OrganizerClient/UpdateSpouseEmail/";
        return DataAccess.postJson(clientInfo, url + clientId);
    },

    getSpouseInfo: (clientId: string) => {
        return DataAccess.get("api/OrganizerClient/GetSpouseInfo/" + clientId);
    },

    getEngagemementLetterDownloadLink: (clientId: string) => {
        return DataAccess.get("api/Download/Mobile/GetEngagementLetterDownloadLink/" + clientId);
    },

    getEngagementLetterFormDataLink: (clientId: string) => {
        return DataAccess.get("api/Sign/GetEngagementLetterFormDataLink/" + clientId);
    },

    getPreparerMessage: (clientId: string) => {
        return DataAccess.get("api/PreparerMessage/GetPreparerMessage/" + clientId);
    },
    getSignedSignatureDocuments :(clientId: string) => {
        let config: any = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
          return DataAccess.getWithConfig('api/Download/Mobile/GetSignedSignatureDocuments/' + clientId, config);
    }
    
};
