import { AxiosResponse } from "axios";
import { actionTypes, headerInfoTypes } from "../../actionTypes";
import { ServiceFactory } from "../../../core/services/dataAccess/factory/ServiceFactory";
import { Dispatch } from "@reduxjs/toolkit";
import { HeaderActionButtonActions } from "redux/reducers/header/headerActionButton";
import { AuthResponse, ErrorStatus } from "models";

export const HeaderInfoAction = {
    getHeaderInfo: (clientGUID: string,
        errorCallback: (status: ErrorStatus) => void) => async (dispatch: any, getState: any) => {
        return await ServiceFactory.GetHeaderInfoService()
            .getHeaderInfo(clientGUID)
            .then(function (response: AxiosResponse<AuthResponse<any>>) {
                const result = response.data;

                if (result.isError) {
                    errorCallback && errorCallback(result.errorStatus);
                    return;
                }
                dispatch({
                    type: headerInfoTypes.receiveHeaderDetails,
                    data: result.data
                });
            })
            .catch(function (error: any) { });
    },
    setHeaderText: (headerTitle: string) => async (dispatch: any, getState: any) => {
        dispatch({
            type: headerInfoTypes.headerTitle,
            data: headerTitle
        });
    },
    requestHeaderInfo: (clientGUID: string) => async (dispatch: any, getState: any) => {
        let state = getState();
        dispatch({ type: headerInfoTypes.headerInfoRequest, id: clientGUID });
        return await ServiceFactory.GetHeaderInfoService()
            .requestHeaderInfo(clientGUID)
            .then(function (response: AxiosResponse<any>) {
                dispatch({
                    type: headerInfoTypes.headerInfoResponse,
                    data: response.data
                });
            })
            .catch(function (error: any) {
                dispatch({ type: headerInfoTypes.headerInfoFailure, id: clientGUID });
            });
    },
    updateClientLogout: (clientId: string, successCallback: () => void) => async (dispatch: any) => {
        return await ServiceFactory.GetHeaderInfoService()
            .updateClientLogout(clientId)
            .then(function () {
                successCallback();
            })
            .catch(function (error: any) {
                console.log(error);
            });
    },  
    getProfileData: (clientId: string) => async (dispatch: any) => {
        return await ServiceFactory.GetHeaderInfoService().getProfileData(clientId).then(function (response: AxiosResponse<any>) {
            dispatch({
                type: headerInfoTypes.setProfileData,
                data: response.data
            });
            })
            .catch(function (error: any) {
                console.log(error);
            });
    },
    setHeaderActionButton: (iconClassName: string, callback: () => void) => (dispatch: Dispatch<HeaderActionButtonActions>) => {
        dispatch({ type: actionTypes.SET_HEADER_ACTION_BUTTON, enabled: true, iconClassName: iconClassName, onClick: callback });
    },
    removeHeaderActionButton: () => (dispatch: Dispatch<HeaderActionButtonActions>) => {
        dispatch({ type: actionTypes.REMOVE_HEADER_ACTION_BUTTON });
    },


};
