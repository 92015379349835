import React from "react";
import { Modal, Button } from "react-bootstrap";

export interface DeclineModalProps {
    show: boolean;
    handleCancel(): void;
    handleConfirm(): void;
}

export const DeclineModal: React.FC<DeclineModalProps> = (props) => {
    return (
        <Modal show={props.show} className="welcome-popup-message decline-modal">
            <Modal.Header>
                <Modal.Title>
                    Reviewed Document Confirmation
                    <button type="button" className="close" onClick={props.handleCancel}>
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    By making this document as <b>reviewed,</b> you are agreeing that you have read through the document(s) and understand its contents.
                    <br />
                    <br />
                    Please confirm you understand the contents and you are declining to sign or complete signing the document.
                </div>
            </Modal.Body >
            <Modal.Footer>
                <Button className="btn btn-primary btn btn-primary pull-right" onClick={props.handleConfirm} data-test-auto="D476F021-66C4-453A-BCB7-8A6112E0B139">
                    Confirm
                </Button>
                <Button className="btn  btn-default logout-no pull-right" onClick={props.handleCancel} data-test-auto="77731EA9-00BB-48B4-8D81-C4A2398C77F3">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
