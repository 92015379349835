export interface IUserModel {
    userId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    phone: string;
    extension: string;
    ptin: string;
    emailAddress: string;
    faxNumber: string;
    title: string;
    createdOn?: Date;
    isDeceased: boolean;
}

export class UserModel implements IUserModel {
    userId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    phone: string;
    extension: string;
    ptin: string;
    emailAddress: string;
    faxNumber: string;
    title: string;
    createdOn?: Date;
    isDeceased: boolean;

    constructor(
        userId: number,
        firstName: string,
        middleName: string,
        lastName: string,
        phone: string,
        extension: string,
        ptin: string,
        emailAddress: string,
        faxNumber: string,
        title: string,
        isDeceased: boolean,
        createdOn?: Date
    ) {
        this.userId = userId;
        this.firstName = firstName;
        this.middleName = middleName;
        this.lastName = lastName;
        this.phone = phone;
        this.extension = extension;
        this.ptin = ptin;
        this.emailAddress = emailAddress;
        this.faxNumber = faxNumber;
        this.title = title;
        this.createdOn = createdOn;
        this.isDeceased = isDeceased;
    }

    public static createNullObject(): IUserModel {
        return new UserModel(0, "", "", "", "", "", "", "", "", "", false, new Date());
    }
}
