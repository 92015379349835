import esignData from "./data/esign.json";
import { ISignerDocumentModel, IClientInfo } from "../../../../../models";

export const MockEsignServices = {
    getEngagementDocument: (clientId: string,forceRefresh?: boolean,successCallback?: any) => {
        return Promise.resolve(esignData.esign);
    },
    submitSignedDocument: (
        clientId: string,
        signedDocument: ISignerDocumentModel,
        successCallback?: () => void,
        failureCallback?: () => void
    ) => {
        return Promise.resolve(esignData.esign);
    },
    skipEngagementSign: (clientId: string, callback?: any) => async (dispatch: any) => {
        return Promise.resolve("");
    },
    getDocumentStatusAndSourceFileSettings: (clientId: string) => {
        return Promise.resolve("");
    },
    getOrganizerMetadata: (clientId: string, callback?: any) => {
        return Promise.resolve("");
    },
    updateSpouseEmail: (clientId: string, clientInfo: IClientInfo) => {
        return Promise.resolve("");
    },
    getSpouseInfo: (clientId: string) => {
        return Promise.resolve("");
    },
    getEngagemementLetterDownloadLink: (clientId: string, callback?: (link: any) => void) => {
        return Promise.resolve("");
    },
    getEngagementLetterFormDataLink: (clientId: string) => {
        return Promise.resolve("");
    },
    getPreparerMessage: (clientId: string) => {
        return Promise.resolve("");
    }, 
    getSignedSignatureDocuments(clientId: string) { 
        return Promise.resolve("");
    }, 
};
