import DownloadDocumentIcon from "./images/icon-download-documents.svg";
import SignIcon from "./images/icon-summary-sign-documents.svg";
import UploadIcon from "./images/icon-upload-documents.svg";
import CameraIcon from "./images/camera.png";
import JpgIcon from "./images/image-jpg.svg";
import PngIcon from "./images/image-png.svg";
import PdfIcon from "./images/pdf.svg";
import WordIcon from "./images/word.svg";
import ExcelIcon from "./images/excel.svg";
import previewIcon from "./images/preview.svg";
import trashIcon from "./images/trash.svg";
import loaderIcon from "./loader.svg";
import messageIcon from "./images/icon-message.svg"
import downloadIcon from "./images/down-arrow-download.svg"

export const images = {
    downloadDocumentIcon: { id: 1, src: DownloadDocumentIcon, alt: "download-document-icon" },
    signIcon: { id: 2, src: SignIcon, alt: "sign-icon" },
    uploadIcon: { id: 3, src: UploadIcon, alt: "upload-icon" },
    cameraIcon: { id: 4, src: CameraIcon, alt: "camera-icon" },
    jpgIcon: { id: 5, src: JpgIcon, alt: "image-icon" },
    pngIcon: { id: 6, src: PngIcon, alt: "image-icon" },
    pdfIcon: { id: 7, src: PdfIcon, alt: "pdf-icon" },
    wordIcon: { id: 8, src: WordIcon, alt: "word-icon" },
    excelIcon: { id: 9, src: ExcelIcon, alt: "excel-icon" },
    previewIcon: { id: 10, src: previewIcon, alt: "preview-icon" },
    trashIcon: { id: 11, src: trashIcon, alt: "trash-icon" },
    loader: { id: 5, src: loaderIcon, alt: "" },
    preparerMessageIcon: {id: 12, src: messageIcon, alt: "preparer-message-icon"},
    download : {id:14,src:downloadIcon,alt :"download-icon"}
};
