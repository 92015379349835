import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OTPAction } from "../../../../redux/actions/otp/otpAction";
import { useParams, useHistory } from "react-router-dom";
import { Header } from "../../../layout/header";
import { ErrorStatus, IBaseClientInfo, IErrorState, IHeaderInfoViewModel } from "../../../../../src/models";
import { HeaderInfoAction } from "../../../../redux/actions";
import { Toaster } from "../../../common";
import { OrganizerConstants } from "../../../../common/constants/constant";

export const OTPPage: React.FC<{}> = (props) => {
    const params: any = useParams();
    const history: any = useHistory();
    const [otpValue, setOtpValue] = useState<string>("");
    const [continueClass, setContinueClass] = useState<string>("btn-primary continue disabled");
    const { baseClientInfoMetaData } = useSelector((state: { baseClientInfoMetaData: IBaseClientInfo }) => state);
    const { headerInfoMetaData } = useSelector((state: { headerInfoMetaData: IHeaderInfoViewModel }) => state);
    const dispatch = useDispatch();
    const [isOTPLocked, setIsOTPLocked] = useState(false); 
    const { message } = useSelector((state: { errorReducer: IErrorState }) => state.errorReducer);
    const [isContinueBtnDisabled, setContinueBtnDisabled] = useState<boolean>(true);


    useEffect(() => {
        dispatch(OTPAction.validateLink(params.id, handleErrorPage));
    }, [dispatch]);

    useEffect(() => {
        dispatch(HeaderInfoAction.getHeaderInfo(params.id, (errorStatus: ErrorStatus) => {
            history.push(`/error/${errorStatus}`);
        }));
    }, [dispatch]);

    const getErrorMessage = (): string => {
       return "This link is no longer valid. Please contact the company that prepared your Organizer.";
    }

    const handleErrorPage = (errorState: any) => {
        if (errorState?.errorCode?.includes("OTP_LOCKED"))
            setIsOTPLocked(true);
    };

    const requestCode = () => {
        dispatch(OTPAction.generateOTP(params.id));
    };

    const verifyOTP = () => {
        dispatch(OTPAction.verifyOTP(otpValue, params.id, handleRedirect, handleErrorPage ));
    };

    const handleRedirect = (clientid: string) => {
        history.push(OrganizerConstants.WelcomeScreenURL + clientid, { fromOTPPage: true });
    };

    let handleOTPChange = (e: any) => {
        if (e.target.value.length <= 6 && (!isNaN(e.target.value) || e.target.value.length === 0)) {
            if (e.target.value.length === 6) {
                setOtpValue(e.target.value);
                setContinueClass("btn-primary continue");
                setContinueBtnDisabled(false);
            } else {
                setOtpValue(e.target.value);
                setContinueClass("btn-primary continue disabled");
                setContinueBtnDisabled(true);
            }
        }
    };

    return (!isOTPLocked?
        <>
            <Toaster />
            <Header
                companyName={headerInfoMetaData.companyName}
                companyLogo={headerInfoMetaData.companyWhiteLogoPath}
            ></Header>
            <div data-testid="otpPage" className="col-lg-12 col-md-12 col-sm-12 col-xs-12 otp-page">
                <div
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12 otp-page-container"
                    style={{ height: "76%", minHeight: "450px", marginTop: "15px" }}
                >
                    <br />
                    <div></div>
                    <div
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        data-delay="5000"
                        style={{ maxWidth: "inherit !important" }}
                    ></div>

                    <span id="spanError" className="error-container" style={{ color: "red" }}></span>
                    <h5 data-test-auto="95CF3D63-4FB0-4EF4-AEC8-36892C281257">Authentication</h5>
                    <br />
                    <h2
                        data-test-auto="52F2AD66-D290-408B-8D2E-FC6CAA94933C"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                    >
                        Access Code Required
                    </h2>

                    <div>
                        <div data-test-auto="2C4D25C9-D9BA-43F0-A2C3-68CC684BE5CC" style={{ fontSize: "14px" }}>
                            <p>
                                Please select “Request Access Code” and we will send you a one-time expiring access code
                                to your email.
                            </p>
                        </div>

                        <div className="margin-top-30">
                            <button
                                data-testid="requestBtn"
                                data-test-auto="AC6EF9C9-2E20-4DB1-A6D6-2DCEB919AD2B"
                                type="button"
                                className=" btn-primary-accesscode continue"
                                id="btnRequestAccessCode"
                                style={{ backgroundColor: "white" }}
                                onClick={requestCode}
                            >
                                Request Access Code
                            </button>
                        </div>

                        <div className="margin-top-30" style={{ fontSize: "14px" }}>
                            <span data-test-auto="A2AE4AE3-E5E1-400A-8FB5-EA496BBC8F0D">Enter access code here:</span>{" "}
                            &nbsp;
                            <br />
                            <input
                                data-testid="otpInput"
                                data-test-auto="BB4B38F7-40BF-4E29-B9B0-29B21CEE3122"
                                id="otp"
                                name="otp"
                                type="text"
                                value={otpValue}
                                onChange={handleOTPChange}
                                style={{ margin: "0 36px 0 4px" }}
                                autoComplete="false"
                                maxLength={6}
                            />
                            <br />
                            <span
                                className="access-code-expiry-info"
                                data-test-auto="8E6D7707-49B0-4A65-BCC3-AB0445104F46"
                            >
                                (This code will expire in 20 minutes)
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style={{ textAlign: "center", marginTop: "15px", display: "flex", justifyContent: "center" }}
                >
                    <button
                        data-testid="continueBtn"
                        data-test-auto="CCBA6C9E-C410-4F81-BA7B-621EDC037461"
                        type="submit"
                        id="btnContinue"
                        className={continueClass}
                        style={{ height: "40px", width: "230px", fontSize: "24px" }}
                        onClick={verifyOTP}
                        disabled={isContinueBtnDisabled}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </>
        : <>
            <Toaster />
            <div className="ErrorContainer" data-testid="errorPageWrapper">
                <div className="Errorheader"></div>
                <div className="ErrorPageContent" data-testid="errorPageContent">
                    <div className="MessageContainer" data-testid="errorMessageContainer">
                        <p className="ErrorMsgPara" data-testid="errorMsg">
                            {message === ""
                                ? getErrorMessage()
                                : message}
                        </p>
                    </div>
                </div>
            </div>
        </>
        );
};
