//All header related reducer must be maintained in a single reducer, the consumer of the store can decide what property of state it needs.

import { actionTypes } from "redux/actionTypes";

export interface HeaderActionButtonState {
    iconClassName: string;
    onClick?: () => void;
    enabled: boolean;
}

const initialHeaderActionButtonState: HeaderActionButtonState = {
    iconClassName: '',
    onClick: undefined,
    enabled: false
}

interface SetHeaderActionButton {
    type: actionTypes.SET_HEADER_ACTION_BUTTON;
    enabled: boolean;
    onClick: () => void;
    iconClassName: string;
}

interface RemoveHeaderActionButton {
    type: actionTypes.REMOVE_HEADER_ACTION_BUTTON;
}

export type HeaderActionButtonActions = SetHeaderActionButton | RemoveHeaderActionButton;

export const reducer = (state: HeaderActionButtonState = initialHeaderActionButtonState, action: HeaderActionButtonActions) => {
    switch (action.type) {
        case actionTypes.SET_HEADER_ACTION_BUTTON:
            return { ...state, enabled: action.enabled, onClick: action.onClick, iconClassName: action.iconClassName };
        case actionTypes.REMOVE_HEADER_ACTION_BUTTON:
            return initialHeaderActionButtonState;
        default:
            return state || initialHeaderActionButtonState;
    }
};