import { organizerTypes } from "../../../redux/actionTypes/";
import { IOrganizerMetadata,OrganizerMetadata } from "./../../../models/organizer/organizer";

const initialState: IOrganizerMetadata = OrganizerMetadata.createNullObject();

export const organizerMetadata = (state = initialState, action: any) => {
    switch (action.type) {
        case organizerTypes.organizerMetaData:
            return action.payload;
        default:
            return state;
    }
};
