export const userTypes = {
    list: "USER_LIST"
};

export const loaderTypes = {
    start: "START_LOADER",
    stop: "STOP_LOADER"
};

export const esignTypes = {
    controlDataList: "FETCH_CONTROL_DATA",
    enagementLetterData: "ENGAGEMENT_DOCUMENT_RESPONSE",
    enagementLetterDataCache: "ENGAGEMENT_DOCUMENT_CACHE_RESPONSE",
    enagementLetterRequestFailure: "ENGAGEMENT_DOCUMENT_FAILURE"
};

export const organizerTypes = {
    organizerMetaData: "ORGANIZER_METADATA_RESPONSE"
};

export const preparerMessageTypes = {
    preparerMessage: "ORGANIZER_PREPARER_MESSAGE_RESPONSE"
};

export const clientTypes = {
    clientData: "CLIENT_INFO_RESPONSE",
    clientDataCache: "CLIENT_INFO_CACHE_RESPONSE",
    clientInfoRequestFailure: "CLIENT_INFO_REQUEST_FAILURE"
};

export const coverPageTypes = {
    startClientDetailsFetch: "START_CLIENT_FETCH",
    receiveClientDetails: "RECEIVE_CLIENT_DETAILS",
    failedClientDetails: "FAILURE_CLIENT_DETAILS"
};

export const headerInfoTypes = {
    receiveHeaderDetails: "RECEIVE_HEADER_DETAILS",
    headerTitle: "HEADER_TITLE",
    headerInfoRequest: "HEADERINFO_REQUEST",
    headerInfoResponse: "HEADERINFO_RESPONSE",
    headerInfoFailure: "HEADERINFO_FAILURE",
    setProfileData: "SET_PROFILE_DATA"
};

export const otpTypes = {
    emailOTP: "EMAIL_OTP",
    setValidOtp: "SET_VALID_OTP_PAGE",
    setInvalidOtp: "SET_INVALID_OTP_PAGE"
};

export const errorTypes = {
    setErrorMsg: "SET_ERROR_MSG"
};

export const documentPageTypes = {
    getBookmarks: "GET_BOOKMARKS",
    getUploadedDocuments: "GET_UPLOADED_DOCUMENTS",
    getUploadedDocumentsByFormId: "GET_UPLOADED_DOCUMENTS_BY_FORMID"
};

export const viewDocumentsPageTypes = {
    getFormIdAndBookmark: "GET_FORMID_AND_BOOKMARK_NAME",
    getDeletedDocuments: "GET_DELETED_DOCUMENTS"
};

export const documentClientTypes = {
    documentClientData: "DOCUMENTCLIENT_INFO_RESPONSE",
    documentClientDataCache: "DOCUMENTCLIENT_INFO_CACHE_RESPONSE",
    documentClientInfoRequestFailure: "DOCUMENTCLIENT_INFO_REQUEST_FAILURE"
};

export enum actionTypes {
    UPLOADED_DOCUMENT_LOADER,
    RECEIVE_UPLOADED_DOCUMENT_STATUS,
    RECEIVE_UPLOADED_DOCUMENT_COMPLETED,
    UPLOADED_DOCUMENT_ERROR,
    UPLOADED_DOCUMENT_ERROR_RESET,
    SET_HEADER_ACTION_BUTTON,
    REMOVE_HEADER_ACTION_BUTTON,
    SET_PROFILE_DATA
};