import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { CommonViewer } from "..";
import { OrganizerConstants } from "../../../common";
import { container, TYPES } from "../../../core/startup";
import { IFileUtilities } from "../../../core/utilities";
import { documentAction } from "../../../redux/actions";

const FileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);

export interface ViewerModalProps {
    show: boolean;
    toggleViewerModal(): void;
    sourceUrl: string;
    sourceFileType: string;
    sourceFileName: string;
    clientId: string;
}

export const ViewerModal: React.FC<ViewerModalProps> = (props: any) => {
    const dispatch: any = useDispatch();
    const params: any = useParams();

    const onDownload = () => {
        const fileName: string = props.sourceFileName;
        if (props.sourceUrl.length > 1) {
            dispatch(
                documentAction.downlodSourceDocument(
                    fileName,
                    params.id)
            );
        }
    };

    const viewer = (sourceFileType: string) => {
        switch (sourceFileType) {
            case "pdf":
                return <CommonViewer documentUrl={props.sourceUrl} clientId={props.clientId} />;

            case "jpg":
            case "jpeg":
            case "png":
                return <img className="viewer-content-image" src={props.sourceUrl} alt="img" />;

            case "xls":
            case "xlsx":
            case "doc":
            case "docx":
                return (
                    <div data-testid="documentViewer" className="viewer-content-docs">
                        <p>{OrganizerConstants.PreviewNotAvailable}</p>
                        <Button data-testid="downloadBtn" className={"btn-primary-extended download-btn"} onClick={onDownload}>
                            Download
                        </Button>
                    </div>
                );

            default:
                return <p>{OrganizerConstants.PreviewNotAvailable}</p>;
        }
    };

    return (
        <div data-testid="viewerModal" className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <Modal show={props.show} size="lg">
                <Modal.Header data-test-auto="320325DC-3043-44C8-A9DB-ACD876903B41">
                    <Modal.Title>
                        <span className="viewer-filename" style={{ fontSize: "1.2rem" }}>
                            {props.sourceFileName}
                        </span>
                        <button data-testid="crossButton" type="button" className="close" onClick={props.toggleViewerModal}>
                            <span aria-hidden="true">x</span>
                        </button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div data-testid="sectionView" className="section-view">{props.sourceUrl && viewer(props.sourceFileType)}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        data-testid="closeButton"
                        data-test-auto="8377C651-D227-4406-8B96-72FFF170E3ED"
                        className={"btn btn-secondary btn-sm"}
                        onClick={props.toggleViewerModal}
                    >
                        <i className="fa fa-sign-out-alt"></i>Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
