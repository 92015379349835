import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userAction } from "../../../redux/actions/userAction";
import { Loader } from "../../common";

export const Home: React.FC<{}> = () => {
    const { users } = useSelector((state: { users: [] }) => state);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userAction.list());
    }, [dispatch]);

    return (
        <div className="App">
            <h3>Home Page</h3>
            <div>
                <h5>Users 👇</h5>
                {users.map((user: { id: number; name: string }) => (
                    <div key={user.id}>{user.name}</div>
                ))}
            </div>
        </div>
    );
};
