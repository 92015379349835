import React, { useEffect, useState } from "react";
import { IClientInfo } from "../../../../../../models";
import { EngagementLetterConstants } from "../../../../../../common";
import { Shimmer } from "../../../../../../components/common";

export interface SpouseInfoProps {
    clientInfo: IClientInfo;
    spouseEmail: string;
    setSpouseEmail(email: string): void;
}

export const SpouseInfo: React.FunctionComponent<SpouseInfoProps> = ({ clientInfo, setSpouseEmail, spouseEmail }) => {
    let handleEmailChange = (event: any) => {
        setSpouseEmail(event.target.value);
    };

    return (
        <div>
            <div className={"col-lg-12 col-sm-12"}>{EngagementLetterConstants.SpouseInfoUpdateModalBodyText}</div>
            <br />
            <br />

            {clientInfo?.id > 0 ? (
                <div>
                    <div className={"col-lg-6 col-sm-12"}>
                        {EngagementLetterConstants.SpouseNameTitle}
                        <strong>{clientInfo.name}</strong>
                    </div>
                    <div className={"col-lg-6 col-sm-12"}>
                        {EngagementLetterConstants.SpouseEmailTitle}
                        <input
                            data-testid="spouseEmail"
                            style={{ width: "100%", marginTop: "5px" }}
                            type="text"
                            value={spouseEmail}
                            onChange={handleEmailChange}
                        />
                    </div>
                </div>
            ) : (
                <Shimmer height={30} />
            )}

            <br />
        </div>
    );
};
