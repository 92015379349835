import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { PhoneNumberComponent } from "../common/PhoneNumberComponent/PhoneNumberComponent";
import { EditableDropdown } from "../common/Select/EditableDropdown";
import { CountryCodes } from "../../common/constants/countryCodes";
import { MessageBox } from "../../components/common/notification/notification";

export interface ChangeMobileNumberProps {
    showState: boolean;
    onSaveNumberClick: (number: string, countrycode: string) => void;
    onHideChangeNumberPopUp: () => void;
    mobileNumber: string;
    countryCode: string;
}

export const ChangeMobileNumber: React.FC<ChangeMobileNumberProps> = (props) => {
    const [editedMobileNumber, setEditedMobileNumber] = useState("");
    const [editedCountryCode, setEditedCountryCode] = useState("");

    const [mobileNumber, setMobileNumber] = useState("");
    const [countryCode, setCountryCode] = useState("");

    let onMobileNumberChange = (value: string) => {
        setEditedMobileNumber(value);
    };

    useEffect(() => {
        setEditedMobileNumber(props.mobileNumber);
        setEditedCountryCode(props.countryCode);

        setMobileNumber(props.mobileNumber);
        setCountryCode(props.countryCode);
    }, [props]);

    let onChangeCountryCode = (value: string) => {
        setEditedCountryCode(value);
    };

    let onHide = () => {
        setEditedMobileNumber(props.mobileNumber);
        setEditedCountryCode(props.countryCode);
        props.onHideChangeNumberPopUp();
    };

    let onSave = () => {
        props.onSaveNumberClick(editedMobileNumber, editedCountryCode);
    }

    let isValidData = (): boolean => {
        return mobileNumber!==editedMobileNumber || countryCode!==editedCountryCode;
    }

    return (
        <>
            <div data-testid="modalContainer" style={{ position: "relative" }}>
                <Modal
                    show={props.showState}
                    onHide={onHide}
                    className="myaccount"
                    data-testid="modal"
                    data-test-auto="3F647974-90BC-47E8-9019-D6BB02CC2601"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <span>Edit Mobile Number</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row marB10">
                            <div className="col-lg-12" style={{ display: "inline" }}>
                                <label className="labelMobileNumber">Enter Mobile Number</label>

                                <div className="myaccount-text phonenumber">
                                    <div className="phoneNumberSelect">
                                        <EditableDropdown
                                            id="ddlCountryCode"
                                            options={CountryCodes()}
                                            onChange={onChangeCountryCode}
                                            selectedValue={editedCountryCode}
                                            data-test-auto="271C2A00-2C22-4E8A-9EEF-966E58AAE121"
                                        />
                                    </div>

                                    <PhoneNumberComponent
                                        phoneNumber={editedMobileNumber}
                                        handleChangePhoneNumber={onMobileNumberChange}
                                        data-test-auto="76F66251-527A-4C72-B07E-1E402EABB663"
                                        className="my-account-phonenumber"
                                    ></PhoneNumberComponent>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            id="btnHideChangeNumberPopUp"
                            onClick={onHide}
                            data-testid="hideChangeNumberPopup"
                            data-test-auto="E68460D2-1A81-418E-BE28-6E7C1F2E395B"
                            variant={"light"}
                            className="btn btn-white btn-default rounded bootbox-cancel"
                        >
                            <i className="fa fa-times"></i> Cancel
                        </Button>
                        <Button
                            onClick={onSave}
                            data-testid="saveNumberOnClick"
                            disabled={!isValidData()}
                            data-test-auto="511110AF-725E-45AD-8CFF-37790FC8B7C1"
                            variant={"primary"}
                            className="btn btn-primary rounded bootbox-accept"
                        >
                            <i className="fa fa-save"></i> Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};
