import {
    Home,
    CoverPage,
    Auth,
    Welcome,
    DocumentViewer,
    EngamentLetterSignCompleted,
    OTPPage,
    MobileOtpPage,
    ErrorPage,
    UploadDocsPage,
    ViewDocumentsPage,
    PreparerMessage
} from "../../components/pages";
import { Layout } from "../../components/layout/layout";

const PATHS: any = [
    { path: "/", component: ErrorPage },
    { path: "/coverPage/:id", component: CoverPage },
    { path: "/auth", component: Auth, layout: Layout },
    { path: "/welcome/:id", component: Welcome, layout: Layout },
    { path: "/engagementletter/sign/:id", component: DocumentViewer, layout: Layout },
    { path: "/engagementletter/signingcomplete/:id", component: EngamentLetterSignCompleted, layout: Layout },
    { path: "/authentication/otp/:id", component: OTPPage },
    { path: "/authentication/mobileotp/:id", component: MobileOtpPage },
    { path: "/preparermessage/:id", component: PreparerMessage, layout: Layout },
    { path: "/error/:errorStatus?", component: ErrorPage },
    { path: "/documents/:id", component: UploadDocsPage, layout: Layout },
    { path: "/viewdocuments/:id", component: ViewDocumentsPage, layout: Layout },
    { path: "/invalidlink", component: ErrorPage },
    { path: "", component: ErrorPage }
];

export const routes = PATHS.map((path: any) => ({
    exact: true,
    ...path
}));
