import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Shimmer } from "../../common";

export interface IWelcomePopupProps {
    taxYear: number;
    clientName: string;
    show: boolean;
    handleClose(): void;
}

export const WelcomePopupModal: React.FC<IWelcomePopupProps> = (props) => {
    return (
        <Modal show={props.show} className="welcome-popup-message">
            <Modal.Body>
                <br />
                <b>
                    Welcome {props.clientName ? props.clientName : <Shimmer height={20} width={100} />},
                    your {props.taxYear} tax documents are ready!
                </b>
                <br />
                <br />
                <p> Using this mobile device you will be able to e-sign documents (when applicable) and upload tax documents.</p>
                <br />
                <p>To fill out your organizer or to print, download, or forward your tax documents, please login using a PC or tablet.</p>
                <br />
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-default btn-round btn-welcome-start" onClick={props.handleClose}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
