import { AxiosResponse } from "axios";
import { ServiceFactory } from "../../../core/services/dataAccess/factory/ServiceFactory";
import { documentClientTypes } from "../../actionTypes";
import { ClientInfo } from "../../../models/index";
import { loaderAction } from "./../loaderAction";
import { MessageBox } from "../../../components/common/notification/notification";
import { ClientInfoActions,OTPPageConstants } from "../../../common";
import { Logger } from "../../../App";

export const organizerAction = {
    updateClientMobileNumber: (clientGuid: string, clientInfo: ClientInfo) => async (dispatch: any, getState: any) => {
        dispatch(loaderAction.start());
        await ServiceFactory.GetOrganizerService()
            .updateClientMobileNumber(clientGuid, clientInfo)
            .then(function (response: AxiosResponse<any>) {
                dispatch(loaderAction.stop());
                MessageBox.Success(ClientInfoActions.SuccessMessage.accountDetailsUpdateSuccess);
            })
            .catch(function () {
                dispatch(loaderAction.stop());
                MessageBox.Error(ClientInfoActions.ErrorMessage.accountDetailsUpdateError);
            });
    },
    getClientInfo: (clientGUID: string) => async (dispatch: any, getState: any) => {
        return await ServiceFactory.GetOrganizerService()
            .getClientInfo(clientGUID)
            .then(function (response: AxiosResponse<any>) {
                dispatch({
                    type: documentClientTypes.documentClientData,
                    payload: response.data
                });
            })
            .catch(function () {
                dispatch({
                    type: documentClientTypes.documentClientInfoRequestFailure
                });
                MessageBox.Error(ClientInfoActions.ErrorMessage.taxpayerFetchError);
            });
    },
    getSessionTimeOutMinutes: (clientGUID: string,successCallback?: any) => async (dispatch: any, getState: any) => {
        return await ServiceFactory.GetOrganizerService()
            .getSessionTimeOutMinutes(clientGUID)
            .then(function (response: AxiosResponse<any>) {
                successCallback && successCallback(response.data);
            })
            .catch(function(error: any) {
                Logger.trackError(
                    `getSessionTimeOutMinutes method failed for clientid: ${clientGUID} due to ${error?.message}`
                );
                MessageBox.Error(OTPPageConstants.ErrorMessage.serverError);
            });
    },
    getRefreshToken: (clientGUID: string,refreshToken:string, successCallback?: any) => async (dispatch: any, getState: any) => {
        return await ServiceFactory.GetOrganizerService()
            .getRefreshToken(clientGUID,refreshToken)
            .then(function (response: AxiosResponse<any>) {
                successCallback && successCallback(response.data);
            })
            .catch(function(error: any) {
                Logger.trackError(
                    `getRefreshToken method failed for clientid: ${clientGUID} due to ${error?.message}`
                );
                successCallback && successCallback('');
            });
    },
};
