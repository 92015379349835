import * as React from "react";
import Select from "react-select";

export interface EditableDropdownProps {
    selectedValue?: any;
    options: any;
    onChange?: (selectedValue: any) => void;
    clearable?: boolean;
    disabled?: boolean;
    customPlaceHolder?: any;
    id: string;
    onFocus?: (event: any) => void;
    trackKeyEvent?: (event: any) => void;
    isCcCountrySelector?: boolean;
}

export const EditableDropdown: React.FC<EditableDropdownProps> = (props) => {
    let selectedValue =
        props.selectedValue && props.options.find((option: any) => option.value === props.selectedValue.toString());

    let onChange = (event: any) => {
        const selectedValue = event;
        if (props.onChange) {
            selectedValue !== null ? props.onChange(selectedValue.value) : props.onChange("");
        }
    };

    return (
        <Select
            id={props.id}
            value={selectedValue}
            onChange={onChange}
            options={props.options}
            placeholder={
                props.customPlaceHolder && props.customPlaceHolder.length > 0 ? props.customPlaceHolder : "Select..."
            }
            isClearable={props.clearable == undefined ? true : props.clearable}
            isDisabled={props.disabled ? props.disabled : false}
            data-toggle="dropdown"
            data-boundary="window"
            onFocus={props.onFocus}
            onKeyDown={(e: any) => {
                props.isCcCountrySelector && props.trackKeyEvent && props.trackKeyEvent(e);
            }}
        />
    );
};
