import React, { useEffect, useState, useRef } from "react";
import { IClientInfo } from "./../../../../../../models";
import { Button, Modal } from "react-bootstrap";
import { EngagementLetterConstants } from "./../../../../../../common";
import { SpouseInfo } from "../spouseInfo/spouseInfo";
import { IUtilities } from "../../../../../../core/utilities";
import { MessageBox } from "../../../../../../components/common";
import { container, TYPES } from "../../../../../../core/startup";

const Utilities = container.get<IUtilities>(TYPES.IUtilities);

interface SpouseInfoModalProps {
    show: boolean;
    clientId: string;
    clientInfo: IClientInfo;
    onClose(): void;
    onRequestSpouseInfo(clientId: string, forceRefresh?: boolean): void;
    onUpdateSpouseEmail(clientInfo: IClientInfo): void;
}

export const SpouseInfoModal: React.FC<SpouseInfoModalProps> = (props) => {
    const [spouseEmail, setSpouseEmail] = useState<string>("");

    useEffect(() => {
        props.onRequestSpouseInfo(props.clientId);
    }, []);

    useEffect(() => {
        setSpouseEmail(props.clientInfo.email);
    }, [props.clientInfo]);

    const handleUpdateEmail = () => {
        if (Utilities.isValidEmailAddress(spouseEmail) == false) {
            MessageBox.Warning(EngagementLetterConstants.SpouseEmailValidationError);
            return;
        }
        props.clientInfo.email = spouseEmail;
        props.onUpdateSpouseEmail(props.clientInfo);
    };

    const onClose = () => {
        setSpouseEmail(props.clientInfo.email);
        props.onClose();
    };

    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <Modal show={props.show} size="lg" data-testid="spouseModal">
                <Modal.Header data-test-auto="320325DC-3043-44C8-A9DB-ACD876903B41">
                    <Modal.Title>
                        {EngagementLetterConstants.SpouseInfoUpdateModalTitle}
                        <button type="button" className="close" onClick={onClose} data-testid="spouseModalCloseIcon">
                            <span aria-hidden="true">x</span>
                        </button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SpouseInfo
                        clientInfo={props.clientInfo}
                        spouseEmail={spouseEmail}
                        setSpouseEmail={setSpouseEmail}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        data-testid="spouseModalCancelButton"
                        data-test-auto="8377C651-D227-4406-8B96-72FFF170E3ED"
                        className={"btn btn-secondary btn-sm"}
                        onClick={onClose}
                    >
                        <i className="fa fa-sign-out-alt"></i>Cancel
                    </Button>

                    <Button
                        className={"btn-primary-extended"}
                        data-test-auto="8377C651-D227-4406-8B96-72FFF170E3ED"
                        data-testid="spouseInfoSubmitButton"
                        onClick={handleUpdateEmail}
                    >
                        <i className="fa fa-sign-out-alt"></i>Save & Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
