import React, { useEffect, useState } from "react";

interface EnagementLetterFooterProps {
    onDownload(): void;
    onBack(): void;
}

export const EnagementLetterSignCompletedFooter: React.FC<EnagementLetterFooterProps> = (props) => {
    return (
        <div data-testid="engagementLetterSignCompletedFooter" className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pull-right download-footer footer-panel">
            <footer>
                <a className="rd-nav-link btn-finish">
                    <button
                        data-testid="backBtn"
                        data-test-auto="d50c4ace-bbe3-11eb-8529-0242ac130003"
                        type="submit"
                        onClick={props.onBack}
                        className="btn btn-secondary btn-sm"
                        id="btnSkip"
                    >
                        Back
                    </button>
                </a>
                <a className="rd-nav-link btn-finish">
                    <button
                        data-testid="downloadBtn"
                        data-test-auto="d50c492a-bbe3-11eb-8529-0242ac130003"
                        type="submit"
                        onClick={props.onDownload}
                        className="btn btn-primary btn-sm pull-right"
                        id="btnFinish"
                    >
                        Download
                    </button>
                </a>
            </footer>
        </div>
    );
};
