import { IPreparerMessage,PreparerMessage } from "./../../../models/organizer/organizer";
import { preparerMessageTypes } from "../../../redux/actionTypes/";


const initialState: IPreparerMessage = PreparerMessage.createNullObject();

export const preparerMessage = (state = initialState, action: any) => {
    switch (action.type) {
        case preparerMessageTypes.preparerMessage:
            return action.payload;
        default:
            return state;
    }
};