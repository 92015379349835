import { errorTypes } from "../../../redux/actionTypes/";
import { IErrorState, ErrorState } from "./../../../models/common/error";

const initialState: IErrorState = ErrorState.createNullObject();

export const errorReducer = (state: ErrorState = initialState, action: any) => {
    switch (action.type) {
        case errorTypes.setErrorMsg:
            return { message: action.payload } as ErrorState;
        default:
            return state || initialState;
    }
};
