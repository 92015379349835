import React, { useState, useEffect } from "react";
import { IUtilities, UtilityFactory } from "../../../core/utilities";

const Utilities: IUtilities = new UtilityFactory().getUtility();

export interface PhoneNumberComponentProps {
    phoneNumber: string;
    handleChangePhoneNumber(value: string): void;
    className?: string;
    trackKeyEvent?: (event: any) => void;
    isCcPhoneInput?: boolean;
    id?: string;
}

export const PhoneNumberComponent: React.FC<PhoneNumberComponentProps> = (props) => {
    const [phoneNumberState, setPhoneNumberState] = useState("");

    useEffect(() => {
        phoneNumberDisplayValue();
    });

    let onChangePhoneNumber = (event: any) => {
        let value = event.target.value;
        let length = value.length;
        let phoneNumber: string = "";
        if (value !== "") {
            if (Utilities.validateTenDigitphoneNumber(value)) {
                if (length <= 5) {
                    if (length > 1) {
                        phoneNumber = value.replace("(", "");
                        phoneNumber = phoneNumber.replace(")", "");
                        if (
                            (phoneNumberState.includes(")") && !value.includes(")")) ||
                            (phoneNumberState.includes("(") && !value.includes("("))
                        )
                            phoneNumber = value;
                        else if (length > 3) phoneNumber = "(" + phoneNumber + ")";
                        else phoneNumber = value;
                    } else {
                        if (length < phoneNumberState.length && length > 1) phoneNumber = value;
                        else {
                            phoneNumber = !value.includes("(") ? "(" : "";
                            phoneNumber = phoneNumber != "" ? phoneNumber + value + ")" : "";
                        }
                    }
                    setPhoneNumberState(phoneNumber);
                }
                if (length >= 6 && length <= 12) {
                    if (length == 6 && !value.includes(" "))
                        phoneNumber = phoneNumberState + " " + value.substring(6, 5);
                    else phoneNumber = value;
                    setPhoneNumberState(phoneNumber);
                }
                if (length >= 10) {
                    if (length == 10 && !value.includes("-"))
                        phoneNumber = phoneNumberState + "-" + event.target.value.substring(10, 9);
                    else phoneNumber = value;
                    setPhoneNumberState(phoneNumber);
                }
                phoneNumber = replaceBrackets(value);
                props.handleChangePhoneNumber(phoneNumber);
            }
        } else {
            phoneNumber = value;
            setPhoneNumberState(phoneNumber);
            props.handleChangePhoneNumber(phoneNumber);
        }
    };

    let replaceBrackets = (value: string): string => {
        let res = value.replace("(", "");
        res = res.replace(")", "");
        res = res.replace(" ", "");
        res = res.replace("-", "");
        return res;
    };

    let phoneNumberDisplayValue = () => {
        let phoneNumber = props.phoneNumber ? props.phoneNumber : "";
        let phoneNumberDisplayvalue: any = Utilities.phoneNumberDisplay(phoneNumber);
        setPhoneNumberState(phoneNumberDisplayvalue);
    };

    return (
        <input
            id={props.id && props.id}
            type="text"
            data-testid="clientMobile"
            data-test-auto="F22C7D00-409F-48E3-AD55-5341EF451C9B"
            className={
                props.className
                    ? "form-control phoneNumeberTextBox custom-input " + props.className
                    : "form-control phoneNumeberTextBox custom-input "
            }
            placeholder="( ) -"
            value={phoneNumberState}
            onChange={onChangePhoneNumber}
            onKeyDown={(e: any) => {
                props.isCcPhoneInput && props.trackKeyEvent && props.trackKeyEvent(e);
            }}
        ></input>
    );
};

export default PhoneNumberComponent;
