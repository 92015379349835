import { DataAccessFactory } from "../../../../utilities/dataAccess/factory/DataAccessFactory";

const DataAccess: any = DataAccessFactory.GetDataAccessService();

export const OTPServices = {
    generateOTP: (clientId: string) => {
        return DataAccess.get("api/OTP/Generate/" + clientId);
    },
    verifyOTP: (otp: string, clientId: string) => {
        return DataAccess.postJson('"'+otp+'"', "api/OTP/Validate/" + clientId);
    },
    generateMobileOTP: (clientId: string) => {
        return DataAccess.get("api/OTP/Mobile/Generate/" + clientId);
    },
    verifyMobileOTP: (otp: string, clientId: string) => {
        return DataAccess.postJson('"'+otp+'"', "api/OTP/Mobile/Validate/" + clientId);
    },
    validateLink: (clientId: string) => {
        return DataAccess.get("api/OTP/" + clientId);
    }
};
