import { SignatureControlRole } from "models/organizer/organizer";

export class Control implements IControl {

    id: string;
    controlType: ControlType;
    name: string;
    readonly: boolean;
    tooltip: string;
    required: boolean;
    boundingRectangle: BoundingRectangle;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;
    controlData:any;


    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean    ) {


        this.id = id;
        this.controlType = controlType;
        this.name = name;
        this.readonly = readonly;
        this.tooltip = tooltip;
        this.required = required;
        this.boundingRectangle = boundingRectangle;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;

    }

}
export enum ControlType {
    Textbox = 1,
    Checkbox = 2,
    Date = 3, 
    Dropdown = 4,
    Grid = 5,
    Signature = 6,
    RadioButton = 7,
    RadioButtonGroup = 8,
    Question = 9,
    Section = 10,
    SignatureCheckBoxControl = 11,
    SignatureRadioButtonControl = 12

}

export class ControlData {
    checked: boolean;
    constructor(checked: boolean) {
        this.checked = checked;
    }
    public static create(checked: boolean) 
    {
        var data = new ControlData(checked);
        return data;
    }
}

export class ChoosableControlData {

    id: string;
    name: string;
    top: number;
    left: number;
    width: number;
    height: number;
    value: any;

    constructor(id: string,
        name: string,
        top: number,
        left: number,
        width: number,
        height: number,
        value: any) {
        this.id = id;
        this.name = name;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.value = value;
    }

    public static create(
        id: string,
        name: string,
        top: number,
        left: number,
        width: number,
        height: number,
        value: any
    ) {

        var data = new ChoosableControlData(id, name, top, left, width, height, value);
        return data;
    }

    public static createNullObject() {

        var data = new ChoosableControlData('', '', 0, 0, 0, 0, { checked: false, value: '' });
        return data;
    }
}
export interface IChoosableControl extends IControl {
    boundingRectangle: IBoundingRectangle,
    items: ChoosableControlData[];
}

export class RadioButtonControl extends Control {

    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        super(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            boundingRectangle,
            customData,
            data,
            disabled);

        this.items = items;
    }

    items: ChoosableControlData[];

    static create(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): CheckBoxControl {

        return new CheckBoxControl(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            items,
            boundingRectangle,
            customData,
            data,
            disabled);
    }
}

export class CheckBoxControl extends Control {

    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        super(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            boundingRectangle,
            customData,
            data,
            disabled);

        this.items = items;
    }
   
    items: ChoosableControlData[];

    static create(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): CheckBoxControl {

        return new CheckBoxControl(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            items,
            boundingRectangle,
            customData,
            data,
            disabled);
    }
}


export interface IControl { 
	id: string;
	top?: number;
	left?: number;
	tooltip: string;
	required: boolean;
	customData: ICustomData[] ;
	data?: IControlData;
	disabled?: boolean;
    controlType: ControlType;
    controlData:any;
}

export interface ISignatureData extends IControlData {
    name: string;
    signature: string;
    signatureMode: any;
}

export interface ITextData extends IControlData {
    text: string;
}

export interface IChoosableControlResult extends IControlData {
    checked: boolean;
    id: string;
}

export class TextData implements ITextData {
    text: string;
    constructor(text: string) {
        this.text = text;
    }
    static create(text: string): ITextData {
        return new TextData(text);
    }
}

export class ChoosableControlResult implements IChoosableControlResult {
    id: string;
    checked: boolean;

    constructor(
        id: string,
        checked: boolean
    ) 
    {
        this.id = id;
        this.checked = checked;
    }

    public static create(
       id: string,
        checked: boolean
    ) {

        var data = new ChoosableControlResult(id, checked);
        return data;
    }
}

export interface IBoundingRectangle{
    left: number;
    top: number;
    width: number;
    height: number;
}

export class BoundingRectangle implements IBoundingRectangle {

    left: number;
    top: number;
    width: number;
    height: number;

    constructor(left: number,
        top: number,
        width: number,
        height: number) {

        this.left = left;
        this.top = left;
        this.width = left;
        this.height = left;

    }
}

export class ControlValue {

    formGuid: string;
    controlGuid: string;
    value: string;

    constructor(
        formGuid: string,
        controlGuid: string,
        value: string) {

        this.formGuid = formGuid;
        this.controlGuid = controlGuid;
        this.value = value;

    }

    public static create(formGuid: string,
        controlGuid: string,
        value: string): ControlValue {

        return new ControlValue(formGuid, controlGuid, value);
    }

}

export interface IControlData {

}

export class TextboxData implements IControlData {
    value: string;

    constructor(value: string) {
        this.value = value;
    }
}

export interface IPage {
	page: number;
	formName: string;
	bookmark: string;
	controls: IControl[];
	allControlSigned: boolean;
}

export class Page implements IPage {
	page: number;
	formName: string;
	bookmark: string;
	controls: IControl[];
	allControlSigned: boolean;

	constructor(
        page: number,
        formName: string, 
        bookmark: string, 
        controls: IControl[], 
        allControlSigned: boolean) 
        {
		this.bookmark = bookmark;
		this.formName = formName;
		this.page = page;
		this.controls = controls;
		this.allControlSigned = allControlSigned;

	}

	public static create(page: number, formName: string, bookmark: string, controls: IControl[], allControlSigned: boolean): IPage {
		return new Page(page, formName, bookmark, controls, allControlSigned);
	}

}

export interface ITextBoxControl extends IControl {
	readOnly: boolean;
}
export interface ICustomData {
	role?: SignatureControlRole;
}

export class CustomTextControl implements ITextBoxControl {

	readOnly: boolean;
	id: string;
	top: number;
	left: number;
	tooltip: string;
	required: boolean;
	customData: ICustomData[];
	data?: IControlData;
	disabled?: boolean;
    controlType: any;
    controlData:any;

	constructor(id: string,
		top: number,
		left: number,
		tooltip: string,
		required: boolean,
		readOnly: boolean,
		customData: ICustomData[],
		data?: IControlData,
		disabled?: boolean) {

		this.id = id;
		this.top = top;
		this.left = left;
		this.tooltip = tooltip;
		this.required = required;
		this.readOnly = readOnly;
		this.customData = customData;
		this.data = data;
		this.disabled = disabled;
	}

	static create(id: string,
		top: number,
		left: number,
		tooltip: string,
		required: boolean,
		readOnly: boolean,
		customData: ICustomData[],
		data?: IControlData,
		disabled?: boolean): ITextBoxControl {

		return new CustomTextControl(id,
			top,
			left,
			tooltip,
			required,
			readOnly,
			customData,
			data,
			disabled);
	}
}