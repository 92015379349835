const snackbar = require("node-snackbar");

export interface ISnackbar {
    show(message: string, duration?: number, actionText?: string, onActionButtonClick?: () => any): void;
}

export const Snackbar = {
    show(message: string, duration?: number, actionText?: string, onActionButtonClick?: () => any): void {
        snackbar.show({
            text: message,
            pos: "bottom-center",
            duration: duration ? duration : 5000,
            actionText: actionText ? actionText : "Dismiss",
            onActionClick: function (element: any) {
                if (onActionButtonClick) {
                    onActionButtonClick();
                } else {
                    element.style.opacity = 0;
                }
            }
        });
    }
};
