import { viewDocumentsPageTypes } from "../../actionTypes";

const initialState: string = "";

export const deletedDocuments = (state = initialState, action: any) => {
    switch (action.type) {
        case viewDocumentsPageTypes.getDeletedDocuments:
            return state.length ? `${state}:${action.payload}` : `${action.payload}`;

        default:
            return state;
    }
};
