import { AxiosResponse } from "axios";
import { coverPageTypes } from "../../actionTypes";
import { ServiceFactory } from "../../../core/services/dataAccess/factory/ServiceFactory";
import { AuthResponse, ErrorStatus, OtpMode } from "../../../models";

export const coverPageAction = {
    getClientInfo: (clientGUID: string,
        errorCallback: (status: ErrorStatus) => void) => async (dispatch: any, getState: any) => {
            dispatch({
                type: coverPageTypes.startClientDetailsFetch
            });
            return await ServiceFactory.GetCoverPageService()
                .getClientInfo(clientGUID)
                .then(function (response: AxiosResponse<AuthResponse<any>>) {
                    
                    const result = response.data;

                    if (result.isError) {
                        errorCallback && errorCallback(result.errorStatus);
                        return;
                    }

                    dispatch({
                        type: coverPageTypes.receiveClientDetails,
                        data: result.data
                    });

                })
                .catch(function (error: any) {
                    dispatch({
                        type: coverPageTypes.failedClientDetails
                    });
                });
        },
    getAuthenticationType:
        (clientId: string,
            callback?: (authenticationType: OtpMode) => void,
            errorCallback?: (status: ErrorStatus) => void) => async (dispatch: any) => {
                await ServiceFactory.GetCoverPageService()
                    .getAuthenticationType(clientId)
                    .then(function (response: AxiosResponse<AuthResponse<OtpMode>>) {
                        
                        const result = response.data;

                        if (result.isError) {
                            errorCallback && errorCallback(result.errorStatus);
                            return;
                        }
                        callback && callback(result.data);
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });
            }
};
