import { Action, ActionCreator, Dispatch, ThunkDispatch } from "@reduxjs/toolkit";
import { ClientTypesNumber, ConflictErrorMessage, ConflictStatusCode, CustomResponse, KnownAction, SourceDocumentStatus, UploadedDocumentStatus } from "redux/reducers/uploadedDocumentStatus/model";
import { loaderAction } from "./../loaderAction";
import { ServiceFactory } from "../../../core/services/dataAccess/factory/ServiceFactory";
import { AxiosResponse } from "axios";
import { MessageBox } from "components/common/notification/notification";
import { actionTypes } from "redux/actionTypes";

export const actionCreators = {

    requestUploadedDocumentStatus: (clientId: string) => async (dispatch: ThunkDispatch<any, any, KnownAction>) => {

        dispatch({ type: actionTypes.UPLOADED_DOCUMENT_LOADER, loading: true });

        try {
            const response: AxiosResponse<UploadedDocumentStatus> = await ServiceFactory
                .GetSourceDocumentStatusService()
                .getUploadedDocumentStatus(clientId);

            dispatch({ type: actionTypes.RECEIVE_UPLOADED_DOCUMENT_STATUS, payload: response.data });

            dispatch({ type: actionTypes.UPLOADED_DOCUMENT_LOADER, loading: false });

        } catch {
            dispatch({ type: actionTypes.UPLOADED_DOCUMENT_LOADER, loading: false });
            MessageBox.Error("Error fetching Uploaded Document Status")
        }
    },

    setUploadedDocumentCompleted: () => (dispatch: ThunkDispatch<any, any, KnownAction>) => {

        dispatch({ type: actionTypes.RECEIVE_UPLOADED_DOCUMENT_COMPLETED, completed: true });

    },

    setUploadedDocumentStatus: (clientType: ClientTypesNumber, documentId: number) => (dispatch: ThunkDispatch<any, any, KnownAction>) => {

        const data: UploadedDocumentStatus = {
            actedBy: clientType,
            status: SourceDocumentStatus.Completed,
            createdOn: new Date(),
            updatedOn: new Date(),
            documentId: documentId,
            id: 0,
            isSourceDocumentCompleted:false
        }

        dispatch({ type: actionTypes.RECEIVE_UPLOADED_DOCUMENT_STATUS, payload: data })

    },

    changeSourceDocumentStatusToCompleted: (
        clientId: string,
        clientType: ClientTypesNumber,
        documentId: number,
        successCallback: () => void
    ) => async (dispatch: ThunkDispatch<any, any, KnownAction | { type: string }>) => {

        dispatch(loaderAction.start());


        try {
            const response: AxiosResponse<CustomResponse<any>> = await ServiceFactory
                .GetSourceDocumentStatusService()
                .changeSourceDocumentStatusToCompleted(clientId);

            if (response.data.statusCode == ConflictStatusCode) {
                dispatch({ type: actionTypes.UPLOADED_DOCUMENT_ERROR, errorMessage: ConflictErrorMessage, isError: true });

                dispatch(actionCreators.setUploadedDocumentCompleted());

                dispatch(actionCreators.requestUploadedDocumentStatus(clientId));

                dispatch(loaderAction.stop());

                return;
            }

            dispatch(actionCreators.setUploadedDocumentStatus(clientType, documentId));

            dispatch(loaderAction.stop());

            successCallback();

        } catch {
            dispatch(loaderAction.stop());
            MessageBox.Error("Error while completing!")
        }
    },

    resetUploadedDocumentError: () => (dispatch: ThunkDispatch<any, any, KnownAction>) => {
        dispatch({ type: actionTypes.UPLOADED_DOCUMENT_ERROR_RESET })
    },

    setUploadedDocumentError: () => (dispatch: ThunkDispatch<any, any, KnownAction>) => {
        dispatch({ type: actionTypes.UPLOADED_DOCUMENT_ERROR, errorMessage: ConflictErrorMessage, isError: true });
    },

}