import {
    UserServices,
    MockUserServices,
    MockEsignServices,
    EsignServices,
    OTPServices,
    CoverPageServices,
    MockCoverPageServices,
    HeaderInfoServices,
    MockHeaderInfoServices,
    DocumentsPageServices,
    MockOrganizerService,
    OrganizerService,
    UploadedDocumentStatusService
} from "../../dataAccess";
import { MockDocumentsPageServices } from "../implemenation/documentsPage/mockDocumentsPageService";

const env: string = "development";

export const ServiceFactory = {
    GetUserService() {
        switch (env) {
            case "development":
                console.log("development mode --> getting data from api");
                return UserServices;
            case "mock-data":
                console.log("no server mode --> getting data from local json");
                return MockUserServices;

            default:
                return MockUserServices;
        }
    },
    GetEsignService() {
        switch (env) {
            case "development":
                console.log("development mode --> getting data from api");
                return EsignServices;
            case "mock-data":
                console.log("no server mode --> getting data from local json");
                return MockEsignServices;

            default:
                return MockEsignServices;
        }
    },
    GetOTPService() {
        switch (env) {
            case "development":
                return OTPServices;
            default:
                return OTPServices;
        }
    },
    GetCoverPageService() {
        switch (env) {
            case "development":
                return CoverPageServices;
            case "mock-data":
                return MockCoverPageServices;

            default:
                return MockCoverPageServices;
        }
    },
    GetHeaderInfoService() {
        switch (env) {
            case "development":
                return HeaderInfoServices;
            case "mock-data":
                return MockHeaderInfoServices;

            default:
                return MockHeaderInfoServices;
        }
    },
    GetDocumentsPageService() {
        switch (env) {
            case "development":
                return DocumentsPageServices;
            case "mock-data":
                return MockDocumentsPageServices;

            default:
                return MockDocumentsPageServices;
        }
    },
    GetOrganizerService() {
        switch (env) {
            case "development":
                return OrganizerService;

            default:
                return MockOrganizerService;
        }
    },
    GetSourceDocumentStatusService(){
        return UploadedDocumentStatusService;
    }
};
