import { AxiosService } from "../AxiosService";
import { FetchService } from "../FetchService";

const env: string = "axios";

export const DataAccessFactory = {
    GetDataAccessService() {
        switch (env) {
            case "axios":
                return AxiosService;
            case "fetch":
                return FetchService;

            default:
                return FetchService;
        }
    }
};
