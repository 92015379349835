import { IOTPState, OTPState } from "../../../models";
import { otpTypes } from "../../actionTypes";

const initialState: IOTPState = OTPState.createNullObject();

export const otpReducer = (state: IOTPState = initialState, action: any) => {
    switch (action.type) {
        case otpTypes.setValidOtp:
            return { mobileNo: action.payload };
        default:
            return state || initialState;
    }
};
