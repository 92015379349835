import React from "react";
import { PdfViewer, Header, ViewPanel, CustomOptions, Pagination, Zoom } from "../../pages";
import Main from "awesome-pdf-viewer/dist/layout/Main";
import Toolbar from "awesome-pdf-viewer/dist/toolbar/Toolbar";
import {  PdfSource } from "awesome-pdf-viewer/dist/viewer/ViewerBase";

export interface ViewProps {
    documentUrl: string;
    clientId: string;
}

const DEFAULT_VIEWER_SCALE: number = 1;

export class CommonViewer extends React.Component<ViewProps> {
    private _viewPanel: any;
    private _controlLayer: any;
    public _controlList: any[] = [];
    private _toolbar: any;
    private _controlDisplayPanel: any;
    private _bookmarkPanel: any;

    constructor(props: any) {
        super(props);
        this.state = {
            hideStartNavigationControl: false,
            signatureData: undefined
        };
    }

    componentDidMount() {
        this.setReferences();
    }

    setReferences() {
        this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
        this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
        this._viewPanel && this._viewPanel.setControlsReference(this._controlList);
        this._viewPanel && this._viewPanel.setControlLayerReference(this._controlLayer);
        this._controlLayer && this._controlLayer.setControlsReference(this._controlList);
        this._controlLayer && this._controlLayer.setcontrolDisplayPanelReference(this._controlDisplayPanel);
        this._controlDisplayPanel && this._controlDisplayPanel.setControlsReference(this._controlList);
        this._controlDisplayPanel && this._controlDisplayPanel.setViewerReference(this._viewPanel);
        this._viewPanel.setBookmarkPanelReference(this._bookmarkPanel);
    }

    componentDidUpdate() {
        this.setReferences();
    }

    public render() {
        return (
            <PdfViewer id={"awesome-pdf-viewer"} readMode={true}>
                <Header>
                    <Toolbar
                        ref={(ref: any) => (this._toolbar = ref)}
                        hideRightPanel={true}
                        hideLeftPanel={true}
                        hideReadOnly={false}
                        showDownload={false}
                    >
                        <Pagination />
                        <Zoom />
                        <CustomOptions />
                    </Toolbar>
                </Header>

                <Main>
                    <ViewPanel 
                    ref={(ref: any) => (this._viewPanel = ref)}
                    url={this.props.documentUrl}
                    pdfSource={PdfSource.createFromUrl(this.props.documentUrl)}
                    ></ViewPanel>
                </Main>
            </PdfViewer>
        );
    }
}
