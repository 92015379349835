import { clientTypes } from "../../../redux/actionTypes/";
import { IClientInfo, ClientInfo } from "./../../../models";

const initialState: IClientInfo = ClientInfo.createNullObject();

export const clientInfo = (state = initialState, action: any) => {
    switch (action.type) {
        case clientTypes.clientData:
        case clientTypes.clientDataCache:
            return action.payload;
        default:
            return state;
    }
};
