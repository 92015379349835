import { images } from "../../../assets/index";

export function Loader() {
    return (
        <div className="loader__container">
            <div className="loader">
                <img src={images.loader.src} alt="loading..." height="100px" width="100px" />
            </div>
        </div>
    );
}
