import { AxiosDataService } from "../../core/services/dataAccess/DataService.Axios";
import { UploadedFile, UploadStatus } from "../../models";

export const helperFunctions = {
    getExtension(fileName: string): string {
        return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    },

    isValidateSize(file: any, maxSize: number): boolean {
        return file.size <= maxSize;
    },

    isValidateExtension(file: any, expectedExtensions: string[]): boolean {
        const fileExtension: string = this.getExtension(file);
        return expectedExtensions.indexOf(fileExtension.toLowerCase()) !== -1;
    },

    normalizeFileName(fileName: string): string {
        var pattern = new RegExp(/[^a-z A-Z 0-9. _\-]+/g);
        let newFileName = fileName.replace(pattern, '_');
        let extension = this.getExtension(fileName);
        return newFileName.replace(new RegExp('\\.' + extension + '$'), `.${extension.toLowerCase()}`)
    }
};

export function convertToUploadedFileModel(files: any, fileNamePrefix: string = ""): UploadedFile[] {
    let uploadedFiles: UploadedFile[] = [];

    for (let i = 0; i < files.length; i++) {
        let tmpFile = files[i];

        if (helperFunctions.isValidateSize(tmpFile, 536870912)) {
            let tmpFileName = fileNamePrefix + tmpFile.name;

            if (
                helperFunctions.isValidateExtension(tmpFileName, [
                    "pdf",
                    "jpg",
                    "png",
                    "jpeg",
                    "xls",
                    "xlsx",
                    "doc",
                    "docx"
                ])
            ) {
                uploadedFiles.push({
                    id: 0,
                    uploadedOn: new Date(),
                    name: helperFunctions.normalizeFileName(tmpFileName),
                    file: tmpFile,
                    status: UploadStatus.Wait,
                    uploadPercentage: 0
                });
            }
        }
    }
    return uploadedFiles;
}

export function handleDuplicates(uploadedFiles: UploadedFile[], existingFiles: []): UploadedFile[] {
    for (let i = 0; i < uploadedFiles.length; i++) {
        let file = uploadedFiles[i];
        let fileName: string = file.name || "";
        let fileExtension = helperFunctions.getExtension(fileName);

        let filecount = 1;
        while (isFileExist(fileName, existingFiles)) {
            fileName = file.name || "";
            fileName = fileName.replace("." + fileExtension, "");
            fileName = fileName + " (" + filecount + ")." + fileExtension;
            filecount++;
        }
        uploadedFiles[i].name = fileName;
    }

    return uploadedFiles;
}

export function isFileExist(fileName: string, existingFiles: any[]): boolean {
    let found: boolean = false;

    for (var i = 0; i < existingFiles.length; i++) {
        if (existingFiles[i].fileName === fileName) {
            found = true;
            break;
        }
    }
    return found;
}

export function initializeAxios(clientId?: string): AxiosDataService {
    return new AxiosDataService(clientId);
}
