import { headerInfoTypes } from "../../actionTypes";

const initialState: string = "";

export const headerTitle = (state = initialState, action: any) => {
    switch (action.type) {
        case headerInfoTypes.headerTitle:
            return action.data;
        default:
            return state;
    }
};
