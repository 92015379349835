import { documentPageTypes } from "../../actionTypes";

const initialState: any[] = [];

export const uploadedDocumentsByFormId = (state = initialState, action: any) => {
    switch (action.type) {
        case documentPageTypes.getUploadedDocumentsByFormId:
            return action.payload;

        default:
            return state;
    }
};
