import { DataAccessFactory } from "../../../../utilities/dataAccess/factory/DataAccessFactory";

const DataAccess: any = DataAccessFactory.GetDataAccessService();

export const UploadedDocumentStatusService = {
    getUploadedDocumentStatus: (clientId: string) => {
        return DataAccess.get(`api/OrganizerAdditionalDocument/GetUploadedDocumentStatus/${clientId}`);
    },
    changeSourceDocumentStatusToCompleted: (clientId: string) => {
        return DataAccess.post(null, `api/OrganizerAdditionalDocument/ChangeSourceDocumentStatusToCompleted/${clientId}`);
    },
};