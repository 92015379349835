import { FormType, GroupType, IDocumentType, UploadMethod } from "models/common/enums";
import { Control, ControlType, ControlValue, IControl, IControlData } from "models/esign/controls";
import { Guid } from "./Guid";
export enum SignatureControlTypes {
    None = 0,
    Signature = 1,
    Initial = 2,
    Date = 3,
    NameAndTitle = 4,
    Text = 10,
    Company = 11
}

export interface ISignatureControlModel {
    signer: string;
    type: SignatureControlTypes;
    role: SignatureControlRole;
    top: number;
    left: number;
    required: boolean;
    tooltip: string;
    controlData: IControlData
}

export enum SignatureControlRole {
    None = 0,
    Taxpayer,
    Spouse,
    ERO,
    PartnerShip
}

export class SignatureControlModel implements ISignatureControlModel {

    signer: string;
    type: SignatureControlTypes;
    top: number;
    left: number;
    role: SignatureControlRole;
    tooltip: string;
    required: boolean;
    controlData: any;

    constructor(signer: string,
        type: SignatureControlTypes,
        top: number,
        left: number,
        role: SignatureControlRole,
        tooltip: string,
        required: boolean,
        controlData: any) {

        this.signer = signer;
            this.type = type;
            this.top = top;
            this.left = left;
            this.role = role;
            this.tooltip = tooltip;
            this.required = required;
            this.controlData = controlData;

    }
}

export interface ISignaturePageData {
    pageNo: number;
    formName: string;
    bookmark: string;
	signingControls: ISignatureControlModel[]
}

export class SignaturePageData implements ISignaturePageData {
    pageNo: number;
    formName: string;
    bookmark: string;
	signingControls: ISignatureControlModel[]


	constructor(
        pageNo: number, 
        formName: string, 
        bookmark: string, 
        signingControls: ISignatureControlModel[]) {
        this.pageNo = pageNo;
        this.bookmark = bookmark;
        this.formName = formName;
		this.signingControls = signingControls;
    }

	public static create(pageNo: number, 
        formName: string,
         bookmark: string,
          signingControls: ISignatureControlModel[]) {
		return new SignaturePageData(pageNo, 
            formName,
            bookmark, 
            signingControls);
    }

}

export class DocumentSignatureDataViewModel {

    id: number;
    name: string;
    fileLink: string;
    disabled: boolean;
    documentType: DocumentType;
    documentControls: ISignaturePageData[];
    fileGuid!: string;
    fileName!: string;
    processInfoGuid!: string;
    sasURL!: string;
    constructor(id: number, 
        name: string, 
        fileLink: string, 
        disabled: boolean, 
        documentType: DocumentType, 
        documentControls: ISignaturePageData[], 
        fileGuid: string,
        fileName: string,
        processInfoGuid: string,
        sasURL: string) {

        this.id = id;
        this.name = name;
        this.fileLink = fileLink;
        this.disabled = disabled;
        this.documentType = documentType;
        this.documentControls = documentControls;
        this.fileGuid = fileGuid;
        this.fileName = fileName;
        this.processInfoGuid = processInfoGuid;
        this.sasURL = sasURL;
    }

    public static create(
        id: number, 
        name: string, 
        fileLink: string, 
        disabled: boolean, 
        documentType: DocumentType, 
        documentControls: ISignaturePageData[]) {
        return new DocumentSignatureDataViewModel(
            id, 
            name, 
            fileLink, 
            disabled, 
            documentType, 
            documentControls,"","","","");
    }

    public static createNullObject() {
        return new DocumentSignatureDataViewModel(
            0, "", "", 
            false, 
            DocumentType.createNullObject(), 
            [],"","","","");
    }

}
export class DocumentType {

    id: number;
    name: string;

    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }


    public static createNullObject() {
        return new DocumentType(0, "");
    }
}

export interface IOrganizerMetadata{
    clientName: string;
    senderName: string;
    taxYear: number;
    isPreview: boolean;
}
export class PageData
{
    pageNo : number;
    calculatedPageNo:number
    constructor(pageNo : number,calculatedPageNo:number)
    {
        this.pageNo =pageNo;
        this.calculatedPageNo = calculatedPageNo;
    }
}
export class PageMap
{
    pages : PageData[];
    pageCount :number;
    group :GroupType;
    fileGuild:string;
    fileName:string;
    folderPath:string;
    constructor(pages : PageData[],
        pageCount :number,
        group :GroupType,
        fileGuild:string,
        fileName:string,
        folderPath:string)
        {
            this.pages = pages;
            this.pageCount = pageCount;
            this.group = group;
            this.fileGuild = fileGuild;
            this.fileName = fileName;
            this.folderPath = folderPath;
        }
}
export class SignerDocumentModel {

    id: number;
    documentGuid: string;
    organizerClients: [];
    formGroup: FormGroup[];
    documentSettings: any;
    documentUrl: string;
    uploadMethod: UploadMethod;
    additionalEsign!: DocumentSignatureDataViewModel[];
    data: any;
    processInfoId!: number;
    fileGuid!: string;
    fileName!: string;
    documentType!: IDocumentType;
    uploadedDocuments: any;
    organizerFormGroupUrl:string = "";
    engagementLetterFormGroupUrl:string = "";
    signDocumentUrl:string = "";
    pageMappings:PageMap[];

    constructor(id: number,
        documentGuid: string,
        organizerClients: [],
        formGroup: FormGroup[],
        documentSettings: any,
        documentUrl: string, uploadMethod: UploadMethod,
        additionalEsign: DocumentSignatureDataViewModel[],
        organizerFormGroupUrl:string,
        engagementLetterFormGroupUrl:string,
        signDocumentUrl:string,
        pageMappings:PageMap[]) {


        this.id = id;
        this.documentGuid = documentGuid;
        this.organizerClients = organizerClients;
        this.formGroup = formGroup;
        this.documentSettings = documentSettings;
        this.documentUrl = documentUrl;
        this.uploadMethod = uploadMethod;
        this.additionalEsign = additionalEsign;
        this.organizerFormGroupUrl = organizerFormGroupUrl;
        this.engagementLetterFormGroupUrl = engagementLetterFormGroupUrl;
        this.signDocumentUrl =signDocumentUrl;
        this.pageMappings = pageMappings;

    }

    public static createNullObject(): SignerDocumentModel {
        return new SignerDocumentModel(
            0, "", [], 
            [{ groupType: GroupType.None, forms: [] }], 
            {}, "", 
            UploadMethod.None,[],"","","", []);
    }

}

export class FormGroup {
    groupType: GroupType;
    forms: Form[];

    constructor(groupType: GroupType,
        forms: Form[]) {

        this.groupType = groupType;
        this.forms = forms;

    }
    public static createNullObject(
        forms: Form[],
        groupType : GroupType): FormGroup {
        return new FormGroup(groupType,forms);
    }

}



export interface IFormData {

}

export class EngagementLetterFormData implements IFormData {
    controlList: Control[];

    constructor(controlList: Control[]) {
        this.controlList = controlList;
    }

    public static createNullObject(controlList: Control[]): EngagementLetterFormData {
        return new EngagementLetterFormData(controlList);
    }
}


export class OrganizerFormData implements IFormData {

    controlValues: ControlValue[];

    constructor(controlValues: ControlValue[]) {
        this.controlValues = controlValues;
    }

    public static create(controlValues: ControlValue[]): OrganizerFormData {
        return new OrganizerFormData(controlValues);
    }

}

export class AdditionalQuestionFormData implements IFormData {
    controlList: Control[];

    constructor(controlList: Control[]) {
        this.controlList = controlList;
    }
}

export class ValueFormData implements IFormData {
    controlValues: ControlValue[];

    constructor(controlValues: ControlValue[]) {
        this.controlValues = controlValues;
    }

    public static createNullObject(): ValueFormData {
        return new ValueFormData([]);
    }
}

export class Form {

    formType: FormType;
    pageNo: number;
    formName: string;
    bookmark: string;
    formData: IFormData;
    formGroupId: string;
    formId: number;
    formGuid: string;
    individualFilePageNo?: number;
    message: string;
    constructor(formType: FormType,
        pageNo: number,
        formName: string,
        bookmark: string,
        formData: IFormData,
        formGroupId: string,
        formId: number,
        formGuid: string,
        message: string,
        individualFilePageNo?: number,
    ) {

        this.formType = formType;
        this.pageNo = pageNo;
        this.formName = formName;
        this.message = message;
        this.bookmark = bookmark;
        this.formData = formData;
        this.formGroupId = formGroupId;
        this.formId = formId;
        this.formGuid = formGuid;
        this.individualFilePageNo = individualFilePageNo;
    }


    public static create(formType: FormType, formGuid: string, formData: IFormData): Form {
        return new Form(formType, 0, "", "", formData, "", 0, formGuid, "", 0);
    }

}

export class OrganizerForm extends Form {
    enabledSourceDocument: boolean;
    constructor(formType: FormType,
        pageNo: number,
        formName: string,
        bookmark: string,
        formData: IFormData,
        formGroupId: string,
        formId: number,
        formGuid: string,
        message: string,
        enabledSourceDocument: boolean,
        individualFilePageNo?: number) {
        super(
            formType,
            pageNo,
            formName,
            bookmark,
            formData,
            formGroupId,
            formId,
            formGuid,
            message,
            individualFilePageNo
        );
        this.enabledSourceDocument = enabledSourceDocument;
    }
}

export class BlobForm {
    formGuid: string;
    controlList: Control[];

    constructor(formGuid: string,
        controlList: Control[]) {
        this.formGuid = formGuid;
        this.controlList = controlList;
    }

    public static createNullObject(): BlobForm {
        return new BlobForm("", []);
    }

}


export class OrganizerMetadata {
    clientName: string;
    senderName: string;
    taxYear: number;
    isPreview: boolean;

    constructor(
        clientName: string,
        senderName: string,
        taxYear: number,
        isPreview: boolean) {
        this.clientName = clientName;
        this.senderName = senderName;
        this.taxYear = taxYear;
        this.isPreview = isPreview;
    }

    public static createNullObject(): OrganizerMetadata {
        return new OrganizerMetadata("", "", 0, false);
    }
}

export class UploadedDocument {
    id: number;
    fileName: string;
    clientId: number;
    uploadedOn: Date;
    documentId: number;
    formId?: number;
    documentGuid: string;
    taxYear: number;
    fileSize:string;

    constructor(id: number,
        fileName: string,
        clientId: number,
        uploadedOn: Date,
        documentId: number,
        documentGuid: string,
        taxYear: number,
        fileSize:string,
        formId?: number,
    ) {
        this.id = id;
        this.fileName = fileName;
        this.clientId = clientId;
        this.uploadedOn = uploadedOn;
        this.documentId = documentId;
        this.formId = formId;
        this.documentGuid = documentGuid;
        this.taxYear = taxYear;
        this.fileSize = fileSize;
    }

    public static createNullObject(): UploadedDocument {
        return new UploadedDocument(0, "", 0, 
        new Date(),
         0,"", 0,"",0);
    }

    public static create(
        fileName: string, 
        fileSize:string, 
        formId?: number): UploadedDocument {
        return new UploadedDocument(
            0, fileName, 0, 
            new Date(), 0, "", 0,
            fileSize, formId);
    }
}


export class ClientInfo {

    id: number;
    name: string;
    email: string;
    SSN: string;
    mobileNumber: string;
    countryCode: string;
    DOB: Date;
    address: string;
    city: string;
    state: string;
    zip: string;
    isDeceased: boolean;
    documentSigner: boolean;


    constructor(id: number,
        name: string,
        email: string,
        SSN: string,
        mobileNumber: string,
        countryCode: string,
        DOB: Date,
        address: string,
        city: string,
        state: string,
        zip: string,
        isDeceased: boolean,
        documentSigner: boolean) {

        this.id = id;
        this.name = name;
        this.email = email;
        this.SSN = SSN;
        this.mobileNumber = mobileNumber;
        this.countryCode = countryCode;
        this.DOB = DOB;
        this.address = address;
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.isDeceased = isDeceased;
        this.documentSigner = documentSigner;
    }

    public static createNullObject(): ClientInfo {
        return new ClientInfo(
            0, "", "", "", "", "", 
            new Date(), "", "", "", "", 
            false, 
            false);
    }

}


export class OrganizerFormDataViewModel {

    formGroup: FormGroup;
    signature: string;
    isReviewed: boolean;

    constructor(formGroup: FormGroup,
        signature: string,
        isReviewed: boolean) {

        this.formGroup = formGroup;
        this.signature = signature;
        this.isReviewed = isReviewed;
    }

    public static create(formGroup: FormGroup,
        signature: string,
        isReviewed: boolean = false): OrganizerFormDataViewModel {
        return new OrganizerFormDataViewModel(formGroup, signature, isReviewed);
    }

    public static createForReview(isReviewed: boolean): OrganizerFormDataViewModel {
        return new OrganizerFormDataViewModel({} as FormGroup, "", isReviewed);
    }
}

//Can extend to support data type, regex, length validations 
export interface IValidator {
    Validate: (data: object, controlType: ControlType) => boolean;
}

export class ValidationModel {
    id: string;
    data: object;
    validators: IValidator[];
    controlType: ControlType;
    pageNumber: number;
    properties: any;

    constructor(id: string,
        value: object,
        controlType: ControlType,
        pageNumber: number,
        properties?: any) {
        this.id = id;
        this.data = value;
        this.controlType = controlType;
        this.validators = [new RequiredValidator()];
        this.pageNumber = pageNumber;
        if (properties) {
            this.setProperties(properties);
        }
    }

    setProperties(properties: any) {
        this.properties = properties;
    }

    Validate() {
        let isValid = true;
        this.validators.forEach(val => {
            if (!val.Validate(this.data, this.controlType)) {
                isValid = false;
                return;
            }
        });
        return isValid;
    }

    public static Create(key: string,
        value: object,
        controlType: ControlType,
        pageNumber: number,
        properties?: any) {
        return new ValidationModel(key, value, controlType, pageNumber, properties);
    }
}

export class RequiredValidator implements IValidator {
    Validate(data: object, controlType: ControlType) {
        switch (controlType) {
            case ControlType.Textbox:
                return (data != null && (data as any).value as String != "");
            case ControlType.RadioButton:
                let isValid = false;
                (data as any).items.forEach(function (radio: any) {
                    if (radio.controlData.selected) {
                        isValid = true;
                        return;
                    }
                });
                return isValid;
        }
        return true;
    }
}

export class CustomQuestionValidationProps {
    questionNumber: number;
    controlId: string;
    constructor(questionNumber: number,
        controlId: string) {
        this.questionNumber = questionNumber;
        this.controlId = controlId;
    }

    public static Create(questionNumber: number,
        controlId: string) {
        return new CustomQuestionValidationProps(questionNumber, controlId);
    }
}

export class PreparerMessage {
    message: string;
    constructor(message: string){
        this.message = message;
    }

    public static createNullObject(): PreparerMessage {
        return new PreparerMessage("");
    }
}
export interface IElForm {
    pageNo: number;
    controls: IControl[];
    allControlSigned?: boolean;
    type: ELDocType, 
    fileGuid?:string
}
export enum ELDocType {    
    EL = 0,
    Additional = 1,
    OrganizerSignDocument = 2
}

export interface IElDocument {

    id: number;
    url: string;
    forms: IElForm[];
    type: ELDocType;
    signCompleted?: boolean;
    fileName?: string;
    documentType: IDocumentType;
    fileGuid: any;
}

export class ElDocument implements IElDocument {
    id: number;
    url: string;
    forms: IElForm[];
    type: ELDocType;
    signCompleted?: boolean;
    fileName: string;
    documentType: IDocumentType;
    fileGuid: any;

    constructor(
        id: number,
        url: string, 
        forms: IElForm[], 
        type: ELDocType, 
        signCompleted: boolean, 
        fileName: string, 
        documentType: IDocumentType, 
        fileGuid: Guid) 
        {
        this.id = id;
        this.url = url;
        this.forms = forms;
        this.type = type;
        this.signCompleted = signCompleted;
        this.fileName = fileName;
        this.documentType = documentType;
        this.fileGuid = fileGuid;

    }

    public static create(
        id: number, 
        url: string, 
        forms: IElForm[], 
        type: ELDocType, 
        signCompleted: boolean, 
        fileName: string, 
        documentType: IDocumentType, 
        fileGuid: Guid): IElDocument 
        {
        return new ElDocument(
            id, url, forms, 
            type, signCompleted, fileName, 
            documentType, fileGuid);
    }

    public static createNullObject(): IElDocument {
        return new ElDocument(
            0, "", [], ELDocType.EL, 
            false, "", IDocumentType.None, 
            Guid.newGuid());
    }

}

export enum SignProcessSteps {
    None = 0,
    Summary = 1,
    SignEngagementLetter = 2,
    CompleteOrganizer = 3
}

export class SignProcessInfo {
    clientId: string;
    lastVisitedStep: SignProcessSteps;
    lastVisitedPageNo: number;

    constructor(clientId: string,
        lastVisitedStep: SignProcessSteps,
        lastVisitedPageNo: number) {
        this.clientId = clientId;
        this.lastVisitedStep = lastVisitedStep;
        this.lastVisitedPageNo = lastVisitedPageNo;
    }
}
export interface IPreparerMessage {
    message: string;
}

export enum ELDocControlState {
    None = 0,
    NoControles = 1,
    RequiredControlExists = 2,
    RequiredControlNotExists = 3,
}
