import { coverPageTypes } from "../../../redux/actionTypes/";
import { BaseClientInfo, IBaseClientInfo } from "../../../models/coverPage/baseClientInfo";
import { Action } from "redux";

const initialState: IBaseClientInfo = BaseClientInfo.createNullObject();

export const baseClientInfoMetaData = (state = initialState, incomingAction: Action) => {
    const action = incomingAction as any;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case coverPageTypes.receiveClientDetails:
            let data = action.data;
            currentState.companyName = data.companyName;
            currentState.logoPath = data.logoPath;
            currentState.taxYear = data.taxYear;
            currentState.clientName = data.clientName;
            currentState.contactAddress = data.contactAddress;
            return { ...currentState, isLoading: false };
        case coverPageTypes.startClientDetailsFetch:
            return { ...currentState, isLoading: true };
        default:
            return state;
    }
};
