import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { esignAction, HeaderInfoAction } from "../../../redux/actions";
import { useParams } from "react-router-dom";
import { IBaseHeaderInfoViewModel, IPreparerMessage } from "../../../models";
import { OrganizerConstants } from "../../../common/constants/constant";
var decodeHtml = require('decode-html');

export const PreparerMessage: React.FC<{}> = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const params: any = useParams();

    const { preparerMessage, baseHeaderInfoMetaData } = useSelector(
        (state: { preparerMessage: IPreparerMessage, baseHeaderInfoMetaData: IBaseHeaderInfoViewModel }) => state
    );

    let loadHeaderTitle = () => {
        dispatch(HeaderInfoAction.setHeaderText(baseHeaderInfoMetaData.companyName));
    };

    useEffect(() => {
        if (isNullorEmpty(preparerMessage.message)) {
            dispatch(esignAction.getPreparerMessage(params.id))
        }
    }, [dispatch]);

    useEffect(() => {
        loadHeaderTitle();
    }, [baseHeaderInfoMetaData]);

    const BackToHome = () => {
        history.push(OrganizerConstants.WelcomeScreenURL + params.id);
    }

    const isNullorEmpty = (expresion: string) => expresion === null || expresion === undefined || expresion.trim() === "";

    return (
        <React.Fragment>
            <div className="welcome-client-page container">
                <div className="preparer-message-page-container">
                    <div className="prepare-message-header">
                        Message from {isNullorEmpty(baseHeaderInfoMetaData.companyName) ? "Tax Preparer" : baseHeaderInfoMetaData.companyName}
                    </div>
                    <br />
                    <div className="preparer-message-content" dangerouslySetInnerHTML={{ __html: decodeHtml(preparerMessage.message) }}></div>
                </div>
                <br />
                <button
                    className="btn btn-secondary btn-sm"
                    onClick={BackToHome}
                    data-test-auto="22DCD9A3-E953-44BB-81AF-3035DE70367F"
                >
                    Back
                </button>
            </div>
        </React.Fragment>);
}