export interface IHeaderInfoViewModel {
    clientName: string;
    taxYear: number;
    companyName: string;
    companyLogoPath: string;
    companyWhiteLogoPath: string;
    contactAddress: any;
}

export class HeaderInfoViewModel implements IHeaderInfoViewModel {
    clientName: string;
    taxYear: number;
    companyName: string;
    companyLogoPath: string;
    companyWhiteLogoPath: string;
    contactAddress: any;

    constructor(
        clientName: string,
        taxYear: number,
        companyName: string,
        companyLogoPath: string,
        companyWhiteLogoPath: string,
        contactAddress: any
    ) {
        this.clientName = clientName;
        this.taxYear = taxYear;
        this.companyName = companyName;
        this.companyLogoPath = companyLogoPath;
        this.companyWhiteLogoPath = companyWhiteLogoPath;
        this.contactAddress = contactAddress;
    }

    public static createNullObject(): IHeaderInfoViewModel {
        return new HeaderInfoViewModel("", 0, "", "", "", "");
    }
}
