export interface IBrandingSettings {
    layoutSetting: IThemeSettings;
    coverPageSetting: IThemeSettings;
}

export interface IThemeSettings {
    bgColorCode: string;
    foreColorCode: string;
}

export const initialThemeSettings: IThemeSettings = {
    bgColorCode: "",
    foreColorCode: ""
};

export const initialBrandingSettings: IBrandingSettings = {
    layoutSetting: initialThemeSettings,
    coverPageSetting: initialThemeSettings
};
export interface IProfileData {
    instrumentationKey: string,
    userId: string,
    companyId: number;
    companyName: string,
    documentId: number;
}
export const initialProfileData: IProfileData = {
    instrumentationKey:"",
    userId:"",
    companyId:0,
    companyName:"",
    documentId:0
}