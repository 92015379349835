export interface IErrorState {
    message: string;
}

export class ErrorState implements IErrorState {
    message: string;

    constructor(message: string) {
        this.message = message;
    }

    public static createNullObject(): IErrorState {
        return new ErrorState("");
    }
}
