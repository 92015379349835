import axios from "axios";
import config from "../../../config";

export const AxiosService = {
    _baseUri: config.apiGateway.url,
    _pageSize: 10,

    constructor(clientId?: string) {},

    set(baseUri: string, pageSize: number) {
        this._baseUri = baseUri;
        this._pageSize = pageSize;
    },

    getPaged(page: number, uriPart?: string | undefined): any {
        return axios.get(this._baseUri + uriPart);
    },

    get(uriPart?: string | undefined, data?: any): any {
        return axios.get(this._baseUri + uriPart, { params: data, method: "get" });
    },

    getWithConfig(uriPart?: string | undefined, config?: any): any {
        return axios.get(this._baseUri + uriPart, config);
    },

    post(data: any, uriPart?: string | undefined, mapResult?: boolean | undefined): any {
        return axios.post(this._baseUri + uriPart, data, {
            headers: { "Content-Type": "multipart/form-data" },
            method: "post"
        });
    },

    put(uriPart: string, data?: any, config?: any, mapResult?: boolean): any {
        return axios.put(this._baseUri + uriPart, data, {
            headers: { "Content-Type": "multipart/form-data" },
            method: "put"
        });
    },

    putJson(data: any, uriPart?: string | undefined): any {
        return axios.put(this._baseUri + uriPart, data, {
            headers: { "Content-Type": "application/json; charset=utf-8" }
        });
    },

    postJson(data: any, uriPart?: string | undefined, mapResult?: boolean | undefined): any {
        return axios.post(this._baseUri + uriPart, data, {
            headers: { "Content-Type": "application/json; charset=utf-8" }
        });
    },

    delete(id: number, uriPart?: string | undefined): any {
        return axios.delete(this._baseUri + uriPart);
    },

    deleteExtended(data: any, uriPart?: any): any {
        throw new Error("Method not implemented.");
    }
};
