import axios from "axios";
import { container, TYPES } from "../../../core/startup";
import { ILocalStore } from "../../../core/utilities";
import { LocalStorageConstant } from "../../../common/constants/constant";
import { OrganizerConstants, OTPPageConstants } from "../../../common/constants/constant";
import { MessageBox } from "../../../components/common";
import { Logger } from "../../../App";

const LocalStorageService = container.get<ILocalStore>(TYPES.ILocalStore);

axios.interceptors.request.use(
    (req) => {
        req.headers = req.headers || {};
        if (req.url) {
            const url = req?.url.split("?")[0];
            const token = LocalStorageService.getItemByUniqueKey(
                req.url.substr(url.lastIndexOf("/") + 1, 36),
                LocalStorageConstant.Token
            );

            req.headers["Authorization"] = "Bearer " + token;
        }
        return req;
    },
    (err) => {
        return Promise.reject(err);
    }
);

axios.interceptors.response.use(
    (res) => {
        return res;
    },
    (error) => {
        if (error.message) {
            Logger.trackError(`Request: ${error.config?.url} failed due to:  ${error.message}`);

            const url = error.config?.url.split("?")[0];
            const uniqueId = url.substr(url.lastIndexOf("/") + 1, 36);
            const encryptedCode = LocalStorageService.getItemByUniqueKey(
                uniqueId,
                LocalStorageConstant.EncryptedClientId
            );

            if (error.message.includes("401")) {
                if (encryptedCode != null) {
                    LocalStorageService.removeItemByUniqueKey(uniqueId, LocalStorageConstant.Token);
                    LocalStorageService.removeItemByUniqueKey(uniqueId, LocalStorageConstant.RefreshToken);
                    LocalStorageService.removeItemByUniqueKey(uniqueId, LocalStorageConstant.EncryptedClientId);
                    window.location.href = OrganizerConstants.CoverPageURL + encryptedCode;
                } else {
                    MessageBox.Info(OTPPageConstants.ErrorMessage.Unauthorized);
                    setTimeout(() => {
                        window.location.href = OrganizerConstants.ErrorPageURL;
                    }, 1000);
                }
            } else if (error.message.includes("Network Error")) {
                if (encryptedCode == null) {
                    MessageBox.Info(OTPPageConstants.ErrorMessage.Unauthorized);
                    setTimeout(() => {
                        window.location.href = OrganizerConstants.ErrorPageURL;
                    }, 1000);
                }
            }
        }
        return Promise.reject(error);
    }
);
