import React from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { ErrorStatus, IErrorState } from "../../../models";
import { Toaster } from "../../common";

export const ErrorPage: React.FC<RouteComponentProps<{ errorStatus?: string; }>> = ({ match: { params: { errorStatus } } }) => {
    const { message } = useSelector((state: { errorReducer: IErrorState }) => state.errorReducer);

    const getErrorMessage = (): string => {
        switch (errorStatus) {
            case ErrorStatus.ClosedByCpa.toString(): return "Your Organizer has been closed to prevent any further use. If you have have any questions please contact your CPA.";
            case ErrorStatus.OrganizerDeleted.toString():
            case ErrorStatus.InvalidLink.toString():
            case ErrorStatus.None.toString():
            case ErrorStatus.OrganizerWithOldCustomQuestion.toString():
            default: return "This link is no longer valid. Please contact the company that prepared your Organizer.";
        }
    }
    
    return (
        <>
            <Toaster />
            <div className="ErrorContainer" data-testid="errorPageWrapper">
                <div className="Errorheader"></div>
                <div className="ErrorPageContent" data-testid="errorPageContent">
                    <div className="MessageContainer" data-testid="errorMessageContainer">
                        <p className="ErrorMsgPara" data-testid="errorMsg">
                            {message === "" 
                                ? getErrorMessage()
                                : message}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};
