import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LocalStorageConstant } from "../../common/constants/constant";
import { ILocalStore } from "../../core/utilities";
import { container, TYPES } from "../../core/startup";
import { HeaderActionButtonState } from "redux/reducers/header/headerActionButton";

const LocalStorageService = container.get<ILocalStore>(TYPES.ILocalStore);

export interface IHeaderProps {
    companyName?: string;
    companyLogo?: string;
}

export const Header: React.FC<IHeaderProps> = (props) => {
    const params: any = useParams();
    const { headerTitle } = useSelector((state: { headerTitle: string }) => state);
    const { enabled, iconClassName, onClick } =
        useSelector((state: { headerActionButtonReducer: HeaderActionButtonState }) => state.headerActionButtonReducer);

    const loggedIn = LocalStorageService.getItemByUniqueKey(params.id, LocalStorageConstant.Token);

    return (
        <>
            <div data-testid="headerContainer" className="row header-container">
                <div className={"header-nav"}>
                    {loggedIn !== null ? (
                        <h6 data-testid="headerTitle" className={"title justtext"}>{headerTitle}</h6>
                    ) : props.companyLogo !== "" ? (
                        <img className="company-logo" src={props.companyLogo} />
                    ) : (
                                <h6 data-testid="companyName" className={"title"}>{props.companyName}</h6>
                    )}
                </div>
            </div>
            {
                enabled &&
                <div onClick={onClick} style={{ margin: 'auto 0' }}>
                    <i className={iconClassName} aria-hidden="true"></i>
                </div>

            }
        </>
    );
};
