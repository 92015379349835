import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OTPAction } from "../../../../redux/actions/otp/otpAction";
import { useParams, useHistory } from "react-router-dom";
import { IOTPState } from "../../../../models";
import { Header } from "../../../layout/header";
import {
  IBaseClientInfo,
  IHeaderInfoViewModel,
} from "../../../../../src/models";
import { HeaderInfoAction } from "../../../../redux/actions";
import { Toaster } from "../../../common";
import { OrganizerConstants } from "../../../../common/constants/constant";

export const MobileOtpPage: React.FC<{}> = (props) => {
  const params: any = useParams();
  const history: any = useHistory();
  const [continueClass, setContinueClass] = useState<string>(
    "btn-primary continue disabled"
  );
  const [otpValue, setOtpValue] = useState<string>("");
  const dispatch = useDispatch();
  const { baseClientInfoMetaData } = useSelector(
    (state: { baseClientInfoMetaData: IBaseClientInfo }) => state
  );
  const { headerInfoMetaData } = useSelector(
    (state: { headerInfoMetaData: IHeaderInfoViewModel }) => state
  );
  const [isContinueBtnDisabled, setContinueBtnDisabled] =
    useState<boolean>(true);

  useEffect(() => {
    dispatch(OTPAction.validateLink(params.id, handleErrorPage));
  }, [dispatch]);

  useEffect(() => {
    dispatch(HeaderInfoAction.getHeaderInfo(params.id, params.errorStatus));
  }, [dispatch]);

  let { mobileNo } = useSelector(
    (state: { otpReducer: IOTPState }) => state.otpReducer
  );
  if (mobileNo.length !== 0) {
    mobileNo = "{xxx}xxx-" + mobileNo.substr(6);
  }
  const handleErrorPage = () => {
    history.push(OrganizerConstants.ErrorPageURL);
  };

  const requestCode = () => {
    dispatch(OTPAction.generateMobileOTP(params.id));
  };

  const verifyOTP = () => {
    dispatch(
      OTPAction.verifyMobileOTP(
        otpValue,
        params.id,
        handleRedirect,
        handleErrorPage
      )
    );
  };

  const handleRedirect = (clientid: string) => {
    history.push(OrganizerConstants.WelcomeScreenURL + clientid, {
      fromOTPPage: true,
    });
  };

  function handleOTPChange(e: any) {
    if (
      e.target.value.length <= 6 &&
      (!isNaN(e.target.value) || e.target.value.length === 0)
    ) {
      if (e.target.value.length === 6) {
        setOtpValue(e.target.value);
        setContinueClass("btn-primary continue");
        setContinueBtnDisabled(false);
      } else {
        setOtpValue(e.target.value);
        setContinueClass("btn-primary continue disabled");
        setContinueBtnDisabled(true);
      }
    }
  }

  return (
    <>
      <Toaster />
      <Header
        companyName={headerInfoMetaData.companyName}
        companyLogo={headerInfoMetaData.companyWhiteLogoPath}
      ></Header>
      <div
        data-testid="mobileOtpPage"
        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 otp-page"
      >
        <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 otp-page-container"
          style={{ height: "76%", minHeight: "450px", marginTop: "15px" }}
        >
          <br />
          <div></div>
          <div
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-delay="5000"
            style={{ maxWidth: "inherit !important" }}
          ></div>

          <span
            id="spanError"
            className="error-container"
            style={{ color: "red" }}
          ></span>
          <h5 data-test-auto="05B20518-61F1-4ADB-A278-D72B255EB0C3">
            Authentication
          </h5>
          <br />
          <h2
            data-test-auto="6CBDBD01-3BAD-4F32-A215-85F6E3797C5D"
            style={{ fontSize: "14px", fontWeight: "bold" }}
          >
            Mobile Access Code Required
          </h2>

          <div>
            <div
              data-test-auto="3B6646C4-BD6E-45B8-90E6-04890B472A93"
              style={{ fontSize: "14px" }}
            >
              <p>
                Please select “Request Access Code” and we will send you a
                one-time expiring access code to your mobile device number:
                <span style={{ fontWeight: "bold" }}> {mobileNo}</span>
              </p>
            </div>
            <div
              data-test-auto="5AAEAFE1-5D28-4AD8-9120-2CEF125D26E4"
              style={{ fontSize: "14px" }}
            >
              <p>
                {" "}
                If the mobile device number is incorrect please contact your Tax
                Professional.
              </p>
            </div>

            <div className="margin-top-30">
              <button
                data-testid="requestBtn"
                data-test-auto="916BFC12-BE3F-4BB8-98A6-A221D5CDFA6B"
                type="button"
                className=" btn-primary-accesscode continue"
                id="btnRequestAccessCode"
                style={{ backgroundColor: "white" }}
                onClick={requestCode}
              >
                Request Access Code
              </button>
            </div>

            <div className="margin-top-30" style={{ fontSize: "14px" }}>
              <span data-test-auto="EEF2A038-CA80-49A0-8407-E13784D92459">
                Enter access code here:
              </span>{" "}
              &nbsp;
              <br />
              <input
                data-testid="mobileOtpInput"
                data-test-auto="B2C35BA2-5393-474A-8391-C98C5E3703F7"
                id="otp"
                name="otp"
                type="text"
                value={otpValue}
                onChange={handleOTPChange}
                style={{ margin: "0 36px 0 4px" }}
                autoComplete="false"
                maxLength={6}
              />
              <br />
              <span
                className="access-code-expiry-info"
                data-test-auto="C5C0CD5A-80D5-4B3B-82CF-8B47905FA927"
              >
                (This code will expire in 20 minutes)
              </span>
            </div>
          </div>
        </div>
        <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
          style={{
            textAlign: "center",
            marginTop: "15px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            data-testid="continueBtn"
            data-test-auto="08F77B2A-CB9C-4B67-9B47-D86CEAD091F6"
            type="submit"
            id="btnContinue"
            className={continueClass}
            style={{ height: "40px", width: "230px", fontSize: "24px" }}
            onClick={verifyOTP}
            disabled={isContinueBtnDisabled}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};
