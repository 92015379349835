import { loaderTypes } from "../actionTypes";

const initialState: boolean = false;

export const loader = (state = initialState, action: any) => {
    switch (action.type) {
        case loaderTypes.start:
            return true;
        case loaderTypes.stop:
            return false;
        default:
            return state;
    }
};
