export enum UploadStatus {
    Wait = 0,
    Initiating = 1,
    Uploading = 2,
    Uploaded = 3,
    Error = 4,
    Converting = 5
}

export enum BatchUploadedStatus {
    Uploading = 1,
    Completed = 2
}

export interface UploadedFile {
    id: number;
    uploadedOn: Date;
    name: string;
    file: any;
    status: UploadStatus;
    uploadPercentage: number;
}

export class DocumentInfo implements UploadedFile {
    id: number;
    uploadedOn: Date;
    name: string;
    file: any;
    status: UploadStatus;
    uploadPercentage: number;

    constructor(id: number, uploadedOn: Date, name: string, file: any, status: UploadStatus, uploadPercentage: number) {
        this.id = id;
        this.uploadedOn = uploadedOn;
        this.name = name;
        this.file = file;
        this.status = status;
        this.uploadPercentage = uploadPercentage;
    }

    public static createNullObject(): UploadedFile {
        return new DocumentInfo(0, new Date(), "", null, 0, 0);
    }
}

export class UploadedDocument {
    id: number;
    fileName: string;
    clientId: number;
    uploadedOn: Date;
    uploadStartedOn: Date;
    documentId: number;
    formId: number;
    fileSize:string;

    constructor(
        id: number,
        fileName: string,
        fileSize:string,
        clientId: number,
        uploadedOn: Date,
        documentId: number,
        formId: number,
        uploadStartedOn: Date
    ) {
        this.id = id;
        this.fileName = fileName;
        this.clientId = clientId;
        this.uploadedOn = uploadedOn;
        this.documentId = documentId;
        this.formId = formId;
        this.uploadStartedOn = uploadStartedOn;
        this.fileSize = fileSize;
    }

    public static createNullObject(): UploadedDocument {
        return new UploadedDocument(0,"", "", 0, new Date(), 0, 0, new Date());
    }

    public static create(fileName: string, fileSize:string, formId: number): UploadedDocument {
        return new UploadedDocument(0, fileName, fileSize, 0, new Date(), 0, formId, new Date());
    }
}

export class UploadedDocumentInfo {
    formId: number;
    documents: UploadedDocument[];

    constructor(formId: number, documents: UploadedDocument[]) {
        this.formId = formId;
        this.documents = documents;
    }

    // public createNullObject(): UploadedDocumentInfo {
    //     return new UploadedDocumentInfo(-1, []);
    // }
}

export interface UploadDocumentsProps {
    clientId: string;
    uploadedDocuments: UploadedDocument[];
    addDocument(
        clientId: string,
        signedDocument: UploadedDocument,
        successCallback?: () => void,
        failureCallback?: () => void
    ): void;
    deleteUploadedDocument(clientId: string, id: number, successCallback?: () => void): void;
    requestUploadedDocuments(clientId: string, forceRefresh: boolean): void;
    // updateUploadedStatus(status: BatchUploadedStatus): void;
    // onUploadedDocumentModification(): void;
    disabled?: boolean;
}

export interface UploadDocumentsState {
    files: UploadedFile[];
}

export interface BookmarkInfo {
    bookMark: string;
    formId: number;
    uploadCount: number;
}